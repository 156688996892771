import { useWeb3React } from "@web3-react/core";
import { useEffect, useState } from "react";

import { injected } from "lib/config";
import { useIsMobile } from "hooks/useDimensions";

function useEagerConnect() {
  const { activate, active } = useWeb3React();
  const isMobile = useIsMobile();
  const [tried, setTried] = useState(false);

  useEffect(() => {
    injected.isAuthorized().then(isAuthorized => {
      if (isAuthorized) {
        activate(injected, undefined, true).catch(() => {
          setTried(true);
        });
        // @ts-ignore TYPE NEEDS FIXING
        window.ethereum.removeAllListeners(["networkChanged"]);
      } else {
        if (isMobile && window.ethereum) {
          activate(injected, undefined, true).catch(() => {
            setTried(true);
          });
          // @ts-ignore TYPE NEEDS FIXING
          window.ethereum.removeAllListeners(["networkChanged"]);
        } else {
          setTried(true);
        }
      }
    });
  }, [activate]); // intentionally only running on mount (make sure it's only mounted once :))

  // if the connection worked, wait until we get confirmation of that to flip the flag
  useEffect(() => {
    if (active) {
      setTried(true);
    }
  }, [active]);

  return tried;
}

export default useEagerConnect;
