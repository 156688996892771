import React, { useState } from "react";
import styled from "styled-components";
import { Box } from "@mui/material";

import AvatarContainer from "components/ds/Containers/AvatarContainer";
import { CircleOutlineIcon } from "components/ds/Icons";

interface ToggleProps {
  variant?: "primary" | "secondary";
  active?: boolean;
  hasIcon?: boolean;
  setActive: any;
  containerProps?: any;
  size?: "small" | "large";
  disabled?: boolean;
}

const Toggle: React.FC<ToggleProps> = ({
  variant = "primary",
  active = false,
  hasIcon = false,
  setActive,
  containerProps,
  disabled = false,
  size = "large",
}) => {
  return (
    <StyledContainer
      variant={variant}
      act={active ? 1 : 0}
      hasicon={hasIcon ? 1 : 0}
      disabled={disabled ? 1 : 0}
      size={size}
      onClick={() => !disabled && setActive(!active)}
      {...containerProps}
    >
      <Box />
      <AvatarContainer scale={0}>
        {hasIcon && (
          <>
            <Box width="3px" height="12px" bgcolor="white" />
            <CircleOutlineIcon />
          </>
        )}
      </AvatarContainer>
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)<any>`
  cursor: pointer;
  display: flex;
  align-items: center;
  width: ${({ size }) => (size === "large" ? 48 : 40)}px;
  height: ${({ size }) => (size === "large" ? 40 : 20)}px;
  position: relative;
  transition: 0.3s;
  background: #05d8e8;
  background: ${({ disabled, variant, act }) => {
    if (disabled) return "#C8C8C8";
    if (variant === "primary") return act ? "#05D8E8" : "#FF296D";
    if (variant === "secondary") return act ? "#6AD4F1" : "#FFAFB7";
  }};
  > div:first-of-type {
    transition: 0.3s;
    left: ${({ act, hasicon, size }) =>
      act ? `calc(100% - ${size === "large" ? (hasicon ? 24 : 28.88) : hasicon ? 15 : 20}px - 4px)` : "4px"};
    width: ${({ hasicon, size }) => (size === "large" ? (hasicon ? 24 : 28.88) : hasicon ? 15 : 20)}px;
    height: ${({ size }) => (size === "large" ? 30 : 15)}px;
    background: white;
    position: absolute;
  }
  > div:nth-of-type(2) {
    transition: 0.3s;
    padding: 4px;
    display: flex;
    justify-content: center;
    > svg {
      position: absolute;
      width: 13.33px;
      height: 13.33px;
      top: 50%;
      right: 3.33px;
      transform: translate(0, -50%);
    }
    > div {
      position: absolute;
      top: 50%;
      left: 8px;
      transform: translate(0, -50%);
    }
    > svg,
    > div {
      transition: 0.3s;
      fill: white;
    }
  }
`;

export default Toggle;
