import { useMemo } from "react";
import { useSubscription } from "use-subscription";

const useCustomSubscription = (rxBehaviourSubject: any) => {
  const subscription = useMemo(
    () => ({
      getCurrentValue: () => rxBehaviourSubject.getValue(),
      subscribe: (cb: any) => {
        rxBehaviourSubject.subscribe(cb);
        return () => null;
      },
    }),
    [rxBehaviourSubject],
  );
  const value = useSubscription(subscription);
  return value;
};

export default useCustomSubscription;
