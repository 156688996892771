import { Web3Provider } from "@ethersproject/providers";
import { Contract, Provider, setMulticallAddress } from "ethers-multicall";
import { Fragment, JsonFragment } from "@ethersproject/abi";

export const singleContractMulticall = async (
  address: string,
  ABI: any,
  method: string,
  args: any[],
  library: Web3Provider,
) => {
  setMulticallAddress(31337, "0xeefba1e63905ef1d7acba5a8513c70307c1ce441");
  const ethcallProvider = new Provider(library, library._network.chainId);
  // await ethcallProvider.init();
  const contract = new Contract(address, ABI);
  let calls = args.map(arg => {
    return Array.isArray(arg) ? contract[method](...arg) : contract[method](arg);
  });
  return await ethcallProvider.all(calls);
};

export const getMulticall = (library: Web3Provider) => {
  return new Provider(library, library._network.chainId);
};

export const getMulticallContract = (address: string, ABI: JsonFragment[] | string[] | Fragment[]): Contract => {
  return new Contract(address, ABI);
};
