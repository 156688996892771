import React, { useCallback, useEffect, useState } from "react";
import useSWR from "swr";
import styled from "styled-components";
import { Box } from "@mui/material";
import { useHistory } from "react-router";

import { Button } from "components/ds/Buttons";
import { addresses } from "lib/constants";
import useActiveWeb3React from "hooks/useActiveWeb3React";
import useWindowDimensions from "hooks/useDimensions";
import LogoWithText from "assets/images/layout/topbar/logo_with_text.svg";
import Connect from "assets/images/layout/topbar/connect.svg";
import Menu from "assets/images/layout/topbar/menu.svg";
import { InfoIcon } from "components/ds/Icons";

interface Props {
  account: any;
  handleConnect: any;
  handleDisconnect: any;
  chainId: number | undefined;
}

const Topbar: React.FC<Props> = ({ account, chainId, handleConnect, handleDisconnect }) => {
  const screenWidth = useWindowDimensions().width;
  const [isMobile, setIsMobile] = useState(false);
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const accountEllipsis = account ? `${account.substring(0, 7)}...${account.substring(account.length - 5)}` : null;

  useEffect(() => {
    if (screenWidth > 1340) {
      setOpen(false);
      setIsMobile(false);
    } else setIsMobile(true);
  }, [screenWidth]);

  const handleNavigation = useCallback(
    path => {
      history.push(path);
      setOpen(false);
    },
    [history],
  );

  return (
    <>
      <StyledContainer>
        <Box pt="25px" pb="20px" pl={!isMobile ? 0.5 : 0}>
          {!isMobile ? (
            <Logo onClick={() => history.push("/")}>
              <img src={LogoWithText} height="32px" alt="" />
            </Logo>
          ) : (
            <MenuButton onClick={() => setOpen(!open)}>
              <img src={Menu} alt="" />
            </MenuButton>
          )}
        </Box>
        {!isMobile ? (
          <>
            <Box display="flex" alignItems="center">
              {accountEllipsis ? (
                <>
                  <ConnectButtonContainer>
                    <Button title={accountEllipsis} onClick={handleDisconnect} />
                  </ConnectButtonContainer>
                </>
              ) : (
                <ConnectButtonContainer>
                  <Button title="Connect Wallet" onClick={handleConnect} />
                </ConnectButtonContainer>
              )}
            </Box>
          </>
        ) : (
          <>
            <Logo onClick={() => history.push("/")}>
              <img src={LogoWithText} height="24px" alt="" />
            </Logo>
            <MenuButton>
              <img src={Connect} alt="" />
            </MenuButton>
          </>
        )}
      </StyledContainer>
      <MobileMenu open={open}>
        {accountEllipsis ? (
          <>
            <MobileActions>
              <Box mt={2}>
                <Button title={accountEllipsis} onClick={handleDisconnect} />
              </Box>
            </MobileActions>
          </>
        ) : (
          <MobileActions>
            <Box mt={2}>
              <Button title="Connect Wallet" onClick={handleConnect} />
            </Box>
          </MobileActions>
        )}
      </MobileMenu>
    </>
  );
};

const Logo = styled(Box)`
  cursor: pointer;
`;

const MobileActions = styled(Box)`
  padding: 1px;
  button {
    width: 100%;
  }
`;

const MobileMenu = styled(Box)<any>`
  padding-left: 16px;
  padding-right: 16px;
  padding-top: ${({ open }) => (open ? 16 : 0)}px;
  padding-bottom: ${({ open }) => (open ? 16 : 0)}px;
  position: fixed;
  top: 50px;
  left: 0px;
  background: #000a20;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: fill-available;
  height: ${({ open }) => (open ? "100vh" : "0vh")};
  transition: 0.3s;
  z-index: 98;
  overflow: hidden;
  > div:first-of-type {
    > div {
      cursor: pointer;
    }
    > div + div {
      margin-top: 1px;
    }
  }
`;

const MenuButton = styled(Box)`
  cursor: pointer;
  transition: 0.3s;
  display: flex;
  align-items: center;
  &:hover {
    opacity: 0.8;
  }
`;

const ConnectButtonContainer = styled(Box)`
  > button {
    width: 221px;
  }
`;

const StyledContainer = styled(Box)`
  position: fixed;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: fill-available;
  z-index: 98;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  background: #000117;
  height: 48px;
  @media (min-width: 1340px) {
    height: 72px;
  }
  border-bottom: 2px solid #ff296d;
  > div:last-of-type {
    > div + div {
      margin-left: 16px;
    }
  }
`;

export default Topbar;
