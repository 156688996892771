import { Web3Provider } from "@ethersproject/providers";
import useSWR from "swr";
import BN from "bignumber.js";

import { addresses } from "lib/constants";
import preJPEG_ABI from "contracts/preJpeg.json";
import useActiveWeb3React from "hooks/useActiveWeb3React";

import { getMulticallContract, getMulticall, getContract } from "lib/functions";

export const releaseTokens = (account: string, chainId: number, library: Web3Provider) => {
  const preJPEG = getContract(chainId ? addresses[chainId].preJPEG : undefined, preJPEG_ABI, library, account);
  return preJPEG.release();
};

export const useVestingInfo = () => {
  const { account, chainId, library } = useActiveWeb3React();
  return useSWR(account && chainId && library ? ["user-vesting-info", chainId] : null, async () => {
    if (!account || !library) return null;
    const prejpegContract = getMulticallContract(chainId ? addresses[chainId].preJPEG : undefined, preJPEG_ABI);
    const multicall = getMulticall(library);
    const [releasableAmount, vestingSchedule, balance] = await multicall.all([
      prejpegContract.releasableAmount(account),
      prejpegContract.vestingSchedules(account),
      prejpegContract.balanceOf(account),
    ]);
    const { totalAllocation, start, cliffDuration, duration, released } = vestingSchedule;
    return {
      releasableAmount: new BN(releasableAmount.toString()).div(1e18).toFixed(),
      totalAllocation: new BN(totalAllocation.toString()).div(1e18).toFixed(),
      start: start.toString(),
      cliffDuration: cliffDuration.toString(),
      duration: duration.toString(),
      released: new BN(released.toString()).div(1e18).toFixed(),
      balance: new BN(balance.toString()).div(1e18).toFixed(),
    };
  });
};
