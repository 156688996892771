import React from "react";
import { Box, BoxProps } from "@mui/material";
import Popover from "components/ds/Popovers";

export const InfoPopoverIcon: React.FC<BoxProps & { description: string; title: string; url?: string } & SvgProps> = ({
  color = "black",
  title,
  description,
  url,
  width,
  height,
}) => (
  <Popover title={title} description={description} url={url}>
    {popoverProps => <Box {...popoverProps}>{<InfoIcon color={color} width={width} height={height} />}</Box>}
  </Popover>
);

interface SvgProps {
  color?: string;
  width?: number | string;
  height?: number | string;
}

const InfoIcon: React.FC<SvgProps> = ({ color = "black", width = 20, height = 20 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${height} ${height}`}
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3 0.916656H9V1.83332H3V0.916656Z" />
      <path d="M2 2.74999V1.83332H3V2.74999H2Z" />
      <path d="M2 8.24999V2.74999H1V8.24999H2Z" />
      <path d="M3 9.16666H2V8.24999H3V9.16666Z" />
      <path d="M9 9.16666V10.0833H3V9.16666H9Z" />
      <path d="M10 8.24999V9.16666H9V8.24999H10Z" />
      <path d="M10 2.74999H11V8.24999H10V2.74999Z" />
      <path d="M10 2.74999V1.83332H9V2.74999H10Z" />
      <path d="M6.5 8.24999H5.5V4.58332H6.5V8.24999Z" />
      <path d="M5.5 3.20832H6.5V4.12499H5.5V3.20832Z" />
    </svg>
  );
};

export default InfoIcon;
