import React from "react";
import styled from "styled-components";
import { Box } from "@mui/material";

interface SvgProps {
  color?: string;
}

const SvgIcon: React.FC<SvgProps> = ({ color = "#FF296D" }) => {
  return (
    <StyledContainer
      component="svg"
      width="22px"
      height="18px"
      {...{ viewBox: "0 0 22 18" }}
      {...{ xmlns: "http://www.w3.org/2000/svg" }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 0V3H0.5V9H3.5V12H6.5V15H9.5V18H12.5V15H15.5V12H18.5V9H21.5V3H18.5V0H12.5V3H9.5V0H3.5ZM3.5 6V3H6.5V6H3.5Z"
      />
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)``;

export default SvgIcon;
