import { BehaviorSubject } from "rxjs";
import { setState } from "lib/functions";

export enum ToastMessages {
  TX_SUBMITTED = "Tx submitted.",
  TX_SUCCESSFUL = "Transaction successful.\nWeenis.",
  TX_FAILED = "Transaction failed.\nTry weening again.",
}

export interface ToastProps {
  variant?: "info" | "success" | "error";
  isOpen?: boolean;
  message?: string;
  duration?: number;
  icon?: string;
  link?: string;
  linkLabel?: string;
  txHash?: string;
}

let timeout: any = null;
export default class UI {
  /**
   * state
   */
  static toastInfo = new BehaviorSubject<ToastProps>({ variant: undefined, isOpen: false, message: "" });
  /**
   * state modifiers
   */

  static success(message?: string, opts?: ToastProps) {
    UI.showToast({
      message: message || ToastMessages.TX_SUCCESSFUL,
      variant: "success",
      ...opts,
    });
  }

  static error(message?: string | null, opts?: ToastProps & { errorCode?: number }) {
    // ignore MM rejected error or others
    if (message === null) return;

    UI.showToast({
      message: message || ToastMessages.TX_FAILED,
      variant: "error",
      ...opts,
    });
  }

  static info(message?: string, opts?: ToastProps) {
    UI.showToast({
      message: message || ToastMessages.TX_SUBMITTED,
      variant: "info",
      ...opts,
    });
  }

  static showToast(attrs: ToastProps) {
    const duration = attrs.duration ?? 10;
    setState(UI.toastInfo, {
      isOpen: true,
      ...attrs,
    });

    if (duration > 0) {
      // hide toast after duration seconds
      timeout = setTimeout(() => {
        UI.hideToast();
      }, duration * 1000);
    }
  }

  static hideToast() {
    setState(UI.toastInfo, {
      isOpen: false,
      icon: undefined,
      link: undefined,
      linkLabel: undefined,
      variant: undefined,
      message: undefined,
      txHash: undefined,
    });
    clearTimeout(timeout);
  }
}
