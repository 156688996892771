import { useWeb3React } from "@web3-react/core";
import { connectorsByName } from "lib/config";
import UI from "lib/store/ui";

const useAuth = () => {
  const { activate, deactivate } = useWeb3React();

  const login = (conectorID: any) => {
    const connector = connectorsByName[conectorID];
    if (connector) {
      activate(connector, (error: Error) => {
        if (error.name === "UnsupportedChainIdError") {
          UI.error("Unsupported chain. Connect to Ethereum Mainnet");
          return;
        }
        UI.error(error.name + " " + error.message);
      });
    } else {
      alert("The connector config is wrong");
    }
  };

  return { login, logout: deactivate };
};

export default useAuth;
