import React from "react";
import styled from "styled-components";
import { Box } from "@mui/material";

interface SvgProps {
  color?: string;
}

const SvgIcon: React.FC<SvgProps> = ({ color = "#FF296D" }) => {
  return (
    <StyledContainer
      component="svg"
      width="20"
      height="20"
      {...{ viewBox: "0 0 20 20" }}
      {...{ fill: color }}
      {...{ xmlns: "http://www.w3.org/2000/svg" }}
    >
      <path d="M9.30588 7.91674H10.6948V9.30563H9.30588V7.91674Z" />
      <path d="M1.66699 1.66699C1.66699 6.29658 1.66699 10.9262 1.66699 15.5559H18.3337C18.3337 10.9262 18.3337 6.29658 18.3337 1.66699H1.66699ZM3.05588 3.05588H16.9448V5.13921H15.5559V6.5281H16.9448V7.91699H15.5559V9.30588H16.9448V10.6948H15.5559V12.0837H16.9448V14.167H3.05588V3.05588Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M4.44477 16.9443V18.3332H7.22255V16.9443H4.44477Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M12.7781 16.9443V18.3332H15.5559V16.9443H12.7781Z" />
      <path d="M7.91699 4.44452H9.30588V5.8334H7.91699V4.44452Z" />
      <path d="M6.5281 5.13896H7.91699V6.52785H6.5281V5.13896Z" />
      <path d="M5.83366 6.52785H7.22255V7.91674H5.83366V6.52785Z" />
      <path d="M5.83366 7.91674H7.22255V9.30563H5.83366V7.91674Z" />
      <path d="M5.83366 9.30563H7.22255V10.6945H5.83366V9.30563Z" />
      <path d="M6.5281 10.6945H7.91699V12.0834H6.5281V10.6945Z" />
      <path d="M7.91699 11.389H9.30588V12.7778H7.91699V11.389Z" />
      <path d="M9.30588 11.389H10.6948V12.7778H9.30588V11.389Z" />
      <path d="M9.30588 4.44452H10.6948V5.8334H9.30588V4.44452Z" />
      <path d="M10.6948 11.389H12.0837V12.7778H10.6948V11.389Z" />
      <path d="M12.0837 10.6945H13.4725V12.0834H12.0837V10.6945Z" />
      <path d="M12.7781 9.30563H14.167V10.6945H12.7781V9.30563Z" />
      <path d="M12.7781 7.91674H14.167V9.30563H12.7781V7.91674Z" />
      <path d="M12.7781 6.52785H14.167V7.91674H12.7781V6.52785Z" />
      <path d="M12.0837 5.13896H13.4725V6.52785H12.0837V5.13896Z" />
      <path d="M10.6948 4.44452H12.0837V5.8334H10.6948V4.44452Z" />
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)``;

export default SvgIcon;
