import React from "react";
import styled from "styled-components";
import { Box } from "@mui/material";

interface SvgProps {
  color?: string;
}

const SvgIcon: React.FC<SvgProps> = ({ color = "#FF296D" }) => {
  return (
    <StyledContainer
      component="svg"
      width="20px"
      height="20px"
      {...{ viewBox: "0 0 20 20" }}
      {...{ fill: color }}
      {...{ xmlns: "http://www.w3.org/2000/svg" }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.33366 3.33337H5.00033H6.66699V5.00004H8.33366V6.66671V8.33337H10.0003V10H11.667V11.6667H10.0003V13.3334V15V16.6667H8.33366V15V13.3334V11.6667H6.66699V10V8.33337H5.00033V10V11.6667V13.3334V15H6.66699V16.6667H5.00033H3.33366H1.66699V15H3.33366V13.3334V11.6667V10V8.33337V6.66671V5.00004H1.66699V3.33337H3.33366ZM11.667 10V8.33337V6.66671H13.3337V5.00004V3.33337H15.0003H16.667H18.3337V5.00004H16.667V6.66671V8.33337V10V11.6667V13.3334V15H18.3337V16.6667H16.667H15.0003H13.3337H11.667V15H13.3337V13.3334V11.6667V10H11.667Z"
        fill="#FF296D"
      />
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)``;

export default SvgIcon;
