import React from "react";
import styled from "styled-components";
import { Box } from "@mui/material";

interface SvgProps {
  color?: string;
}

const SvgIcon: React.FC<SvgProps> = ({ color = "#FF296D" }) => {
  return (
    <StyledContainer
      component="svg"
      width="14px"
      height="8px"
      {...{ viewBox: "0 0 14 8" }}
      {...{ fill: color }}
      {...{ xmlns: "http://www.w3.org/2000/svg" }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0003 -1.49776e-06L13.667 0L13.667 2.66667L11.0003 2.66666L11.0003 5.33333L8.33366 5.33333L8.33366 7.99999L5.66699 7.99999L5.66699 5.33333L3.00033 5.33332L3.00033 2.66666L0.333662 2.66666L0.333664 -7.4888e-06L11.0003 -1.49776e-06Z"
        fill="white"
      />
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)``;

export default SvgIcon;
