import React from "react";
import styled from "styled-components";
import { Box } from "@mui/material";

interface SvgProps {
  color?: string;
}

const SvgIcon: React.FC<SvgProps> = ({ color = "#FF296D" }) => {
  return (
    <StyledContainer
      component="svg"
      width="20px"
      height="20px"
      {...{ viewBox: "0 0 20 20" }}
      {...{ fill: color }}
      {...{ xmlns: "http://www.w3.org/2000/svg" }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0003 2.5V4.16667H16.667V5.83333H18.3337V7.5H16.667H15.0003V9.16667V10.8333V12.5H13.3337V14.1667H11.667V15.8333H10.0003V17.5H8.33366H6.66699H5.00033V15.8333H3.33366H1.66699V14.1667H3.33366H5.00033V12.5H3.33366V10.8333H1.66699V9.16667H3.33366V7.5V5.83333H5.00033V7.5H6.66699H8.33366V5.83333H10.0003V4.16667H11.667V2.5H13.3337H15.0003ZM3.33366 5.83333H1.66699V4.16667H3.33366V5.83333Z"
        fill="#FF296D"
      />
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)``;

export default SvgIcon;
