import { ChainId } from "./network";

interface IDictionary {
  [id: number]: IDictionary | string | any;
}

export const addresses: IDictionary = {
  [ChainId.ETHEREUM]: {
    preJPEG: "0xBFF28C0B8c3DF7B89e71743e835A7be2C98599f6",
  },
  [ChainId.KOVAN]: {
    AIRDROP: "0xa3C2b4CB7aa9183D4943E7CC3dEA4a563E001062", // @TODO: replace this with the correct address once deployed
  },
};

export default addresses;
