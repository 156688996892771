/**
 * Do not edit directly
 * Generated on Sun, 20 Mar 2022 23:10:04 GMT
 */

export const regularH1 = {"fontFamily":"Minecraftia","fontWeight":400,"lineHeight":"44px","fontSize":"36px","letterSpacing":"0%","paragraphSpacing":"0px","textCase":"$textCase.none","textDecoration":"none"};
export const regularH2 = {"fontFamily":"Minecraftia","fontWeight":400,"lineHeight":"28px","fontSize":"24px","letterSpacing":"0%","paragraphSpacing":"0px","textCase":"$textCase.none","textDecoration":"none"};
export const regularH3 = {"fontFamily":"Minecraftia","fontWeight":400,"lineHeight":"24px","fontSize":"20px","letterSpacing":"0%","paragraphSpacing":"0px","textCase":"$textCase.none","textDecoration":"none"};
export const regularH4 = {"fontFamily":"Minecraftia","fontWeight":400,"lineHeight":"20px","fontSize":"16px","letterSpacing":"0%","paragraphSpacing":"0px","textCase":"$textCase.none","textDecoration":"none"};
export const regularH5 = {"fontFamily":"Minecraftia","fontWeight":400,"lineHeight":"16px","fontSize":"14px","letterSpacing":"0%","paragraphSpacing":"0px","textCase":"$textCase.none","textDecoration":"none"};
export const regularH6 = {"fontFamily":"Minecraftia","fontWeight":400,"lineHeight":"14px","fontSize":"12px","letterSpacing":"0%","paragraphSpacing":"0px","textCase":"$textCase.none","textDecoration":"none"};
export const regularH7 = {"fontFamily":"Minecraftia","fontWeight":400,"lineHeight":"12px","fontSize":"10px","letterSpacing":"0%","paragraphSpacing":"0px","textCase":"$textCase.none","textDecoration":"none"};
export const boldH1 = {"fontFamily":"Minecraftia","fontWeight":700,"lineHeight":"44px","fontSize":"36px","letterSpacing":"0%","paragraphSpacing":"0px","textCase":"$textCase.none","textDecoration":"none"};
export const boldH2 = {"fontFamily":"Minecraftia","fontWeight":700,"lineHeight":"28px","fontSize":"24px","letterSpacing":"0%","paragraphSpacing":"0px","textCase":"$textCase.none","textDecoration":"none"};
export const boldH3 = {"fontFamily":"Minecraftia","fontWeight":700,"lineHeight":"24px","fontSize":"20px","letterSpacing":"0%","paragraphSpacing":"0px","textCase":"$textCase.none","textDecoration":"none"};
export const boldH4 = {"fontFamily":"Minecraftia","fontWeight":700,"lineHeight":"20px","fontSize":"16px","letterSpacing":"0%","paragraphSpacing":"0px","textCase":"$textCase.none","textDecoration":"none"};
export const boldH5 = {"fontFamily":"Minecraftia","fontWeight":700,"lineHeight":"16px","fontSize":"14px","letterSpacing":"0%","paragraphSpacing":"0px","textCase":"$textCase.none","textDecoration":"none"};
export const boldH6 = {"fontFamily":"Minecraftia","fontWeight":700,"lineHeight":"14px","fontSize":"12px","letterSpacing":"0%","paragraphSpacing":"0px","textCase":"$textCase.none","textDecoration":"none"};
export const boldH7 = {"fontFamily":"Minecraftia","fontWeight":700,"lineHeight":"12px","fontSize":"10px","letterSpacing":"0%","paragraphSpacing":"0px","textCase":"$textCase.none","textDecoration":"none"};
export const italicH1 = {"fontFamily":"Minecraftia","fontWeight":400,"lineHeight":"44px","fontSize":"36px","letterSpacing":"0%","paragraphSpacing":"0px","textCase":"$textCase.none","textDecoration":"none"};
export const italicH2 = {"fontFamily":"Minecraftia","fontWeight":400,"lineHeight":"28px","fontSize":"24px","letterSpacing":"0%","paragraphSpacing":"0px","textCase":"$textCase.none","textDecoration":"none"};
export const italicH3 = {"fontFamily":"Minecraftia","fontWeight":400,"lineHeight":"24px","fontSize":"20px","letterSpacing":"0%","paragraphSpacing":"0px","textCase":"$textCase.none","textDecoration":"none"};
export const italicH4 = {"fontFamily":"Minecraftia","fontWeight":400,"lineHeight":"20px","fontSize":"16px","letterSpacing":"0%","paragraphSpacing":"0px","textCase":"$textCase.none","textDecoration":"none"};
export const italicH5 = {"fontFamily":"Minecraftia","fontWeight":400,"lineHeight":"16px","fontSize":"14px","letterSpacing":"0%","paragraphSpacing":"0px","textCase":"$textCase.none","textDecoration":"none"};
export const italicH6 = {"fontFamily":"Minecraftia","fontWeight":400,"lineHeight":"14px","fontSize":"12px","letterSpacing":"0%","paragraphSpacing":"0px","textCase":"$textCase.none","textDecoration":"none"};
export const italicH7 = {"fontFamily":"Minecraftia","fontWeight":400,"lineHeight":"12px","fontSize":"10px","letterSpacing":"0%","paragraphSpacing":"0px","textCase":"$textCase.none","textDecoration":"none"};
export const boldItalicH1 = {"fontFamily":"Minecraftia","fontWeight":700,"lineHeight":"44px","fontSize":"36px","letterSpacing":"0%","paragraphSpacing":"0px","textCase":"$textCase.none","textDecoration":"none"};
export const boldItalicH2 = {"fontFamily":"Minecraftia","fontWeight":700,"lineHeight":"28px","fontSize":"24px","letterSpacing":"0%","paragraphSpacing":"0px","textCase":"$textCase.none","textDecoration":"none"};
export const boldItalicH3 = {"fontFamily":"Minecraftia","fontWeight":700,"lineHeight":"24px","fontSize":"20px","letterSpacing":"0%","paragraphSpacing":"0px","textCase":"$textCase.none","textDecoration":"none"};
export const boldItalicH4 = {"fontFamily":"Minecraftia","fontWeight":700,"lineHeight":"20px","fontSize":"16px","letterSpacing":"0%","paragraphSpacing":"0px","textCase":"$textCase.none","textDecoration":"none"};
export const boldItalicH5 = {"fontFamily":"Minecraftia","fontWeight":700,"lineHeight":"16px","fontSize":"14px","letterSpacing":"0%","paragraphSpacing":"0px","textCase":"$textCase.none","textDecoration":"none"};
export const boldItalicH6 = {"fontFamily":"Minecraftia","fontWeight":700,"lineHeight":"14px","fontSize":"12px","letterSpacing":"0%","paragraphSpacing":"0px","textCase":"$textCase.none","textDecoration":"none"};
export const boldItalicH7 = {"fontFamily":"Minecraftia","fontWeight":700,"lineHeight":"12px","fontSize":"10px","letterSpacing":"0%","paragraphSpacing":"0px","textCase":"$textCase.none","textDecoration":"none"};