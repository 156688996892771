import { useCallback, useState } from "react";
import { Box, useTheme, Typography, Link } from "@mui/material";
import moment from "moment";
import BN from "bignumber.js";

import useActiveWeb3React from "hooks/useActiveWeb3React";
import { JPEGCigarIcon, Button, VestingIcon, ParameterCard, ProgressBar } from "components/ds";
import {
  useVestingInfo,
  releaseTokens,
  formatTxErrorMessage,
  convertToInternationalCurrencySystem,
} from "lib/functions";
import { useIsMobile } from "hooks/useDimensions";
import UI, { ToastMessages } from "lib/store/ui";

const Claim = () => {
  const { account, chainId, library } = useActiveWeb3React();
  const { data: vestingInfo, mutate: refreshVestingInfo } = useVestingInfo();
  const { releasableAmount, totalAllocation, start, cliffDuration, duration, released, balance } = vestingInfo || {};
  const isMobile = useIsMobile();

  // UI
  const theme = useTheme();
  const [releaseLoading, setReleaseLoading] = useState(false);

  const handleRelease = useCallback(async () => {
    if (!account || !chainId || !library) return;
    try {
      const tx = await releaseTokens(account, chainId, library);
      if (tx) {
        UI.info(ToastMessages.TX_SUBMITTED, { txHash: tx.hash });
        setReleaseLoading(true);
        await tx.wait();
        refreshVestingInfo();
        UI.success(ToastMessages.TX_SUCCESSFUL);
      }
    } catch (error) {
      console.error("handleClaimAirdrop", error);
      UI.error(formatTxErrorMessage(error));
    }
    setReleaseLoading(false);
  }, [account, chainId, library, refreshVestingInfo]);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" mt={["70px", "100px"]}>
      <Box display="flex" width="100%">
        <Box flex={1} display={["none", "block"]} />
        <Box flex={3} display="flex" alignItems="center" ml={1}>
          <VestingIcon />
          <Typography variant="h2" color="primary900" fontFamily="'Minecraftia Bold'" ml={1}>
            Vesting
          </Typography>
        </Box>
        <Box flex={1} display={["none", "block"]} />
      </Box>
      <Box
        boxShadow={`${theme.shadows[0]}, 0 0 0 1px ${theme.palette.secondary300}`}
        width={["97%", "100%"]}
        maxWidth="750px"
        display="flex"
        flexDirection="column"
        bgcolor="primary600"
        alignItems="center"
        mt={["30px", "60px"]}
      >
        <Box display="flex" flexDirection="column" alignItems="center" width="100%" bgcolor="primary300">
          <Box
            width="100%"
            height="40px"
            bgcolor="secondary90"
            position="relative"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="h3" color="primary900" fontFamily="'Minecraftia Bold'" mr={1}>
              Welcome to the citadel anon
            </Typography>
            <JPEGCigarIcon color={theme.palette.primary900} />
            {/* <Box position="absolute" right={1} top={2}>
              <Link href={`${explorer}/address/${aidropAddress}`} target="_blank">
                <ExternalLinkIcon color={theme.palette.primary10} />
              </Link>
            </Box> */}
          </Box>
          <Box display="flex" flexDirection="column" bgcolor="primary600" p={2} width={["85%", "95%"]}>
            <Box display="flex" mt="32px" width="100%">
              <ParameterCard
                name="Your cliff end"
                value={`${
                  Number(start) && Number(cliffDuration)
                    ? moment(new BN(start).plus(cliffDuration).times(1000).toNumber()).format("MMMM Do YYYY")
                    : "-"
                } `}
                valueProps={{ variant: isMobile ? "h2" : "h1" }}
                align="center"
              />
              <ParameterCard
                name="Your vesting end"
                value={`${
                  Number(start) && Number(duration)
                    ? moment(new BN(start).plus(duration).times(1000).toNumber()).format("MMMM Do YYYY")
                    : "-"
                } `}
                valueProps={{ variant: isMobile ? "h2" : "h1" }}
                align="center"
              />
            </Box>
            <Box display="flex" mt="32px" width="100%">
              <ParameterCard
                name={isMobile ? "JPEG to claim" : "JPEG remaining to claim"}
                value={`${
                  totalAllocation !== undefined && released !== undefined
                    ? convertToInternationalCurrencySystem(new BN(totalAllocation).minus(released).toFixed())
                    : "-"
                } JPEG`}
                valueProps={{ variant: isMobile ? "h2" : "h1" }}
                align="center"
              />
              <ParameterCard
                name={isMobile ? "JPEG claimed" : "JPEG already claimed"}
                value={`${released ? convertToInternationalCurrencySystem(released) : "-"} JPEG`}
                valueProps={{ variant: isMobile ? "h2" : "h1" }}
                align="center"
              />
            </Box>

            <Typography color="primary60" variant="h5" fontFamily="'Minecraftia Bold'" mt="32px" mb={1}>
              Vesting progress
            </Typography>
            <ProgressBar start={new BN(start).toNumber()} end={new BN(start).plus(duration).toNumber()} />

            <ParameterCard
              name="JPEG available to claim now"
              value={`${releasableAmount ? convertToInternationalCurrencySystem(releasableAmount) : "-"} JPEG`}
              valueProps={{ variant: "h1" }}
              align="center"
              mt="50px"
            />

            <Box width="100%" display="flex" justifyContent="center">
              <Button
                title="Claim"
                mt={2}
                disabled={!Number(releasableAmount) || releaseLoading}
                loading={releaseLoading}
                onClick={handleRelease}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default Claim;
