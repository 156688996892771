import React from "react";
import styled from "styled-components";
import { Box } from "@mui/material";

interface SvgProps {
  color?: string;
}

const SvgIcon: React.FC<SvgProps> = ({ color = "#FF296D" }) => {
  return (
    <StyledContainer
      component="svg"
      width="18"
      height="16"
      {...{ viewBox: "0 0 18 16" }}
      {...{ fill: color }}
      {...{ xmlns: "http://www.w3.org/2000/svg" }}
    >
      <path d="M0.666992 15.8181H17.3337V2.18191H9.75794V3.69694H15.8184V14.3029H2.18221V11.2727H0.666992V15.8181Z" />
      <path d="M2.18221 3.69694H0.666992V5.21216H2.18221V3.69694Z" />
      <path d="M8.24271 6.72719H6.72749V8.24241H8.24271V6.72719Z" />
      <path d="M8.24272 3.69694V2.18191H5.21246V0.666687H3.69724V2.18191H2.18221L2.18221 3.69694L3.69724 3.69694V5.21216L2.18221 5.21216L2.18221 6.72719H3.69724V8.24241H0.666992V9.75763H3.69724V11.2727H5.21246V9.75763H6.7275L6.72749 8.24241L5.21246 8.24241V6.72719L6.72749 6.72719L6.7275 5.21216H5.21246V3.69694H8.24272Z" />
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)``;

export default SvgIcon;
