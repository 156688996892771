import { BoxProps, SvgIcon, Box } from "@mui/material";

export { default as CircleOutlineIcon } from "./CircleOutlined";
export { default as InfoIcon } from "./Info";
export * from "./Info";
export { default as VaultIcon } from "./Vault";
export { default as SearchIcon } from "./Search";
export { default as PoolsIcon } from "./Pools";
export { default as LockingIcon } from "./Locking";
export { default as DocsIcon } from "./Docs";
export { default as GamesIcon } from "./Games";
export { default as ExpandMoreIcon } from "./ExpandMore";
export { default as DollarIcon } from "./Dollar";
export { default as DiscordIcon } from "./Discord";
export { default as MediumIcon } from "./Medium";
export { default as TwitterIcon } from "./Twitter";
export { default as LifeIcon } from "./Life";
export { default as TokenSaleIcon } from "./TokenSale";
export { default as FightIcon } from "./Fight";
export { default as Fight1Icon } from "./Fight1";
export { default as SquigglesIcon } from "./Squiggles";
export { default as LoaderIcon } from "./Loader";

type IconProps = BoxProps & {
  color?: string;
  width?: string | number;
  height?: string | number;
  viewBox?: string | undefined;
};

const SvgContainer: React.FC<IconProps> = ({ height = 20, width = 20, color, viewBox, children }) => (
  <Box
    component="svg"
    width={width}
    height={height}
    {...{ viewBox: viewBox || `0 0 ${width} ${height}` }}
    {...{ fill: color }}
    {...{ xmlns: "http://www.w3.org/2000/svg" }}
  >
    {children}
  </Box>
);

export const MintWithdrawIcon: React.FC<IconProps> = ({ color = "black", width = 25, height = 25 }) => {
  return (
    <SvgContainer width={width} height={height} color={color}>
      <path d="M13 4V2H11V4H9V6L7 6V8H9V6H11V9H13V6L15 6V8H17V6H15V4L13 4Z" />
      <path d="M13 15H15V17H13V15Z" />
      <path d="M11 15V13H13V15H11Z" />
      <path d="M11 17H9V15H11V17Z" />
      <path d="M11 17V19H13V17H11Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 10V22H22V10H2ZM18 18H20V20H18V18ZM18 14H20V12H18V14ZM6 14H8V12H16V14H18V18H16V20H8V18H6V14ZM6 14H4V12H6V14ZM4 18H6V20H4V18Z"
      />
    </SvgContainer>
  );
};

export const MoneyRectangleIcon: React.FC<IconProps> = ({ color = "black", width = 20, height = 20 }) => {
  return (
    <SvgContainer width={width} height={height} color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.334 20H40.0007V33.3333H26.6673V46.6667H13.334V60H26.6673V73.3333H40.0007V86.6667H13.334V100H40.0007V113.333H53.334V100H66.6673V86.6667H80.0007V73.3333H66.6673V60H53.334V46.6667H80.0007V33.3333H53.334V20ZM66.6673 73.3333V86.6667H53.334V73.3333H66.6673ZM26.6673 60H40.0007V46.6667H26.6673V60Z"
      />
      <path d="M13.334 140H146.667V33.3333H93.334V46.6667H133.334V126.667H26.6673V113.333H13.334V140Z" />
    </SvgContainer>
  );
};

export const UmbrellaIcon: React.FC<IconProps> = ({ color = "black", width = "20", height = "20" }) => {
  return (
    <SvgContainer width={width} height={height} color={color}>
      <path d="M60 66.6668V53.3335H73.3333V66.6668H60Z" />
      <path d="M100 66.6668H86.6667V53.3335H100V66.6668Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M86.6667 13.3335H73.3333V26.6668H46.6667V40.0002H33.3333V66.6668H20V93.3335H73.3333V133.334H86.6667V146.667H100V133.334H113.333V120H100V133.334H86.6667V93.3335H140V66.6668H126.667V40.0002H113.333V26.6668H86.6667V13.3335ZM113.333 66.6668V40.0002H86.6667V53.3335H73.3333V40.0002H46.6667V66.6668H33.3333V80.0002H46.6667V66.6668H60V80.0002H100V66.6668H113.333ZM113.333 66.6668V80.0002H126.667V66.6668H113.333Z"
      />
    </SvgContainer>
  );
};

export const ListIcon: React.FC<IconProps> = ({ color = "#FF296D", width = "20", height = "20" }) => {
  return (
    <SvgContainer width={width} height={height} color={color}>
      <rect width="20" height="20" />
      <path d="M1.66602 1.66669V5.00002H4.99935V1.66669H1.66602Z" />
      <path d="M1.66602 8.33335V11.6667H4.99935V8.33335H1.66602Z" />
      <path d="M1.66602 15H4.99935V18.3334H1.66602V15Z" />
      <path d="M6.66602 1.66669V5.00002H18.3327V1.66669H6.66602Z" />
      <path d="M6.66602 8.33335H18.3327V11.6667H6.66602V8.33335Z" />
      <path d="M6.66602 15V18.3334H18.3327V15H6.66602Z" />
    </SvgContainer>
  );
};

export const GridIcon: React.FC<IconProps> = ({ color = "#0178C5", width = "20", height = "20" }) => {
  return (
    <SvgContainer width={width} height={height} color={color}>
      <rect width="20" height="20" />
      <path d="M1.66699 1.66669V8.33335H8.33366V1.66669H1.66699Z" />
      <path d="M11.667 1.66669V8.33335H18.3337V1.66669H11.667Z" />
      <path d="M1.66699 11.6667H8.33366V18.3334H1.66699V11.6667Z" />
      <path d="M11.667 11.6667V18.3334H18.3337V11.6667H11.667Z" />
    </SvgContainer>
  );
};

export const DepositIcon: React.FC<IconProps> = ({ color = "black", width = "24", height = "24" }) => (
  <SvgContainer width={width} height={height} color={color}>
    <path d="M11 2H13V5H15V7H13V9H11V7H9V5H11V2Z" />
    <path d="M9 5H7V3H9V5Z" />
    <path d="M15 5V3H17V5H15Z" />
    <path d="M13 15H15V17H13V15Z" />
    <path d="M11 15V13H13V15H11Z" />
    <path d="M11 17H9V15H11V17Z" />
    <path d="M11 17V19H13V17H11Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 10V22H22V10H2ZM18 18H20V20H18V18ZM18 14H20V12H18V14ZM6 14H8V12H16V14H18V18H16V20H8V18H6V14ZM6 14H4V12H6V14ZM4 18H6V20H4V18Z"
    />
  </SvgContainer>
);

export const ChadIcon: React.FC<IconProps> = ({ color = "black", width = "24", height = "24" }) => (
  <SvgContainer width={width} height={height} color={color}>
    <path d="M6 2H16V4.5H8.5V7H16V4.5H18.5V19.5H16V14.5H13.5V12H16V9.5H8.5V12H11V14.5H8.5V19.5H6V2Z" />
    <path d="M11 14.5H13.5V17H11V14.5Z" />
    <path d="M8.5 19.5H16V22H8.5V19.5Z" />
  </SvgContainer>
);

export const ArrowIcon: React.FC<IconProps> = ({ color = "white", width = "36", height = "36" }) => (
  <SvgContainer width={width} height={height} color={color}>
    <path d="M9.66699 16.3333L13.0003 16.3333L13.0003 19.6667H16.3337L16.3337 23L19.667 23L19.667 19.6667H23.0003V16.3333H26.3337V13L23.0003 13V16.3333L19.667 16.3333V19.6667L16.3337 19.6667V16.3333H13.0003V13L9.66699 13L9.66699 16.3333Z" />
  </SvgContainer>
);

export const HandleArrowIcon: React.FC<IconProps> = ({ color = "white", width = 12, height = 14 }) => (
  <SvgContainer width={width} height={height} color={color}>
    <path d="M5.33333 0.333252V2.99992H2.66667V5.66659H0V8.33325H2.66667V10.9999H5.33333V13.6666H8V10.9999H5.33333V8.33325H12V5.66659H5.33333V2.99992H8V0.333252H5.33333Z" />
  </SvgContainer>
);

export const LiquidationIcon: React.FC<IconProps> = ({ color = "white", width = 24, height = 25 }) => (
  <SvgContainer width={width} height={height} color={color}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 2.5H20V4.5H18V6.5H2V18.5H4V20.5H2V22.5H4V20.5H6V18.5H22V6.5H20V4.5H22V2.5ZM20 16.5H18V14.5H20V16.5ZM18 10.5H20V8.5L18 8.5V10.5ZM14 10.5H18V14.5H16V16.5H10V14.5H11V15.5H13V13.5H15V11.5H14V10.5ZM11 11.5V9.5H13V10.5H14V8.5H8V10.5H6V8.5H4V10.5H6V14.5H4V16.5H6V14.5H10V13.5H9V11.5H11ZM12 12.5V13.5H13V12.5H12ZM12 12.5V11.5H11V12.5H12Z"
    />
  </SvgContainer>
);

export const GovernanceIcon: React.FC<IconProps> = ({ color = "white", width = 20, height = 18 }) => (
  <SvgContainer width={width} height={height} color={color}>
    <path d="M11 4H13V8H9V6H11V4Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M6 6V0H16V6H18V8H16V10H18V12H4V10H6V8H4V6H6ZM14 2H8V10H14V2Z" />
    <path d="M2 10H4V8H2V10Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M20 10H22V20H0V10H2V14H20V10ZM2 16V18H20V16H2Z" />
    <path d="M20 10V8H18V10H20Z" />
  </SvgContainer>
);

export const JPEGCigarIcon: React.FC<IconProps> = ({ width = 32, height = 32 }) => (
  <SvgContainer width={width} height={height}>
    <rect x="9.33398" y="18.667" width="14.6667" height="2.66667" />
    <rect x="9.33398" y="21.334" width="10.6667" height="2.66667" fill="#C4C4C4" />
    <rect x="24" y="21.334" width="2.66667" height="2.66667" />
    <rect x="21.334" y="2.66699" width="2.66667" height="13.3333" fill="#C4C4C4" />
    <rect x="6.66602" y="21.334" width="2.66667" height="2.66667" />
    <rect x="9.33398" y="24" width="14.6667" height="2.66667" />
    <rect x="20" y="21.334" width="4" height="2.66667" fill="#C65021" />
  </SvgContainer>
);

export const LeafIcon = ({ color = "black", width = 20, height = 20 }) => (
  <SvgContainer width={width} height={height} color={color}>
    <path d="M10.8333 1.66699H9.16667V3.33366H7.5V5.00033H5.83333V6.66699H4.16667V8.33366H2.5V11.667H4.16667V13.3337H9.16667V15.0003H7.5V16.667H5.83333V18.3337H14.1667V16.667H12.5V15.0003H10.8333V13.3337H15.8333V11.667H17.5V8.33366H15.8333V6.66699H14.1667V5.00033H12.5V3.33366H10.8333V1.66699Z" />
  </SvgContainer>
);

export const ExternalLinkIcon: React.FC<IconProps> = ({ color = "black", width = 20, height = 20 }) => (
  <SvgContainer width={width} height={height} color={color}>
    <path d="M20 0V8H18V4H16V6H14V4H16V2H12V0H20Z" />
    <path d="M12 8V6H14V8H12Z" />
    <path d="M10 10V8H12V10H10Z" />
    <path d="M10 10V12H8V10H10Z" />
    <path d="M0 2V18H18V10H16V16H2V4H10V2H0Z" />
    {/* <path d="M16.8337 0.5V7.16667H15.167V3.83333H13.5003V5.5H11.8337V3.83333H13.5003V2.16667H10.167V0.5H16.8337Z" />
    <path d="M10.167 7.16667V5.5H11.8337V7.16667H10.167Z" />
    <path d="M8.50033 8.83333V7.16667H10.167V8.83333H8.50033Z" />
    <path d="M8.50033 8.83333V10.5H6.83366V8.83333H8.50033Z" />
    <path d="M0.166992 2.16667V15.5H15.167V8.83333H13.5003V13.8333H1.83366V3.83333H8.50033V2.16667H0.166992Z" /> */}
  </SvgContainer>
);

export const ParachuteIcon: React.FC<IconProps> = ({ color = "white", width = 18, height = 18 }) => (
  <SvgContainer width={width} height={height} color={color}>
    <path d="M7.33268 0.666016H10.666V2.33268H13.9993V3.99935H15.666V5.66602H17.3327V8.99935H15.666V7.33268H12.3327V10.666H10.666V7.33268H7.33268V10.666H5.66602V7.33268H2.33268V8.99935H0.666016V5.66602H2.33268V3.99935H3.99935V2.33268H7.33268V0.666016Z" />
    <path d="M3.99935 10.666H5.66602V12.3327H3.99935V10.666Z" />
    <path d="M7.33268 13.9993H5.66602V12.3327H7.33268V10.666H10.666V12.3327H12.3327V13.9993H10.666V17.3327H7.33268V13.9993Z" />
    <path d="M13.9993 10.666H15.666V8.99935H13.9993V10.666Z" />
    <path d="M13.9993 10.666V12.3327H12.3327V10.666H13.9993Z" />
    <path d="M3.99935 10.666V8.99935H2.33268V10.666H3.99935Z" />
  </SvgContainer>
);

export const CloseIcon: React.FC<IconProps> = ({ width = 20, height = 20, color = "black" }) => (
  <SvgContainer width={width} height={height} color={color}>
    <path d="M10.9997 2.99998H8.33301V5.66665H10.9997V2.99998Z" />
    <path d="M5.66634 8.33331H2.99967V11H5.66634V8.33331Z" />
    <path d="M2.99967 11H0.333008V13.6666H2.99967V11Z" />
    <path d="M13.6663 0.333313H10.9997V2.99998H13.6663V0.333313Z" />
    <path d="M5.66634 2.99998H2.99967V5.66665H5.66634V2.99998Z" />
    <path d="M8.33301 5.66665H5.66634V8.33331H8.33301V5.66665Z" />
    <path d="M10.9997 8.33331H8.33301V11H10.9997V8.33331Z" />
    <path d="M13.6663 11H10.9997V13.6666H13.6663V11Z" />
    <path d="M2.99967 0.333313H0.333008V2.99998H2.99967V0.333313Z" />
  </SvgContainer>
);

export const AlertIcon: React.FC<IconProps> = ({ width = 32, height = 32, color }) => (
  <SvgContainer width={width} height={height} color={color}>
    <path d="M13 9H11V11H13V9Z" />
    <path d="M13 12H11V18H13V12Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 3V5H7V9H5V13H3V21H21V13H19V9H17V5H15V3H9ZM19 19V15H17V11H15V7H13V5H11V7H9V11H7V15H5V19H19Z"
    />
  </SvgContainer>
);

export const CheckIcon: React.FC<IconProps> = ({ width = 32, height = 32, color }) => (
  <SvgContainer width={width} height={height} color={color}>
    <path d="M20 6H18V8H16V10H14V12H12V14H10V16H8V14H6V12H4V14H6V16H8V18H10V16H12V14H14V12H16V10H18V8H20V6Z" />
  </SvgContainer>
);

export const ToastInfoIcon: React.FC<IconProps> = ({ width = 20, height = 20, color }) => (
  <SvgContainer width={width} height={height} color={color}>
    <path d="M6 2H18V4H6V2Z" />
    <path d="M4 6V4H6V6H4Z" />
    <path d="M4 18V6H2V18H4Z" />
    <path d="M6 20H4V18H6V20Z" />
    <path d="M18 20V22H6V20H18Z" />
    <path d="M20 18V20H18V18H20Z" />
    <path d="M20 6H22V18H20V6Z" />
    <path d="M20 6V4H18V6H20Z" />
    <path d="M13 18H11V10H13V18Z" />
    <path d="M11 7H13V9H11V7Z" />
  </SvgContainer>
);

export const VestingIcon: React.FC<IconProps> = ({
  width = 18,
  height = 20,
  color = "black",
  viewBox = "0 0 18 20",
}) => (
  <SvgContainer width={width} height={height} color={color} viewBox={viewBox}>
    <path d="M10 8H8V10H10V8Z" fill="#000A20" />
    <path d="M12 8H14V12H12V8Z" fill="#000A20" />
    <path d="M6 8H4V12H6V8Z" fill="#000A20" />
    <path d="M8 12H10V14H8V12Z" fill="#000A20" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6 0H4V2H2V6H0V18H2V20H8V18H10V20H16V18H18V6H16V2H14V0H12V2H10V4H8V2H6V0ZM6 2V4H8V6H10V4H12V2H14V6H16V18H10V16H8V18H2V6H4V2H6Z"
      fill="#000A20"
    />
  </SvgContainer>
);
