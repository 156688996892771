import React, { useState } from "react";
import { useLocation } from "react-router";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import styled from "styled-components";
import GlobalStyle from "lib/utils/GlobalStyle";
import { useWeb3React } from "@web3-react/core";

import Topbar from "./Topbar";

import { useIsMobile } from "hooks/useDimensions";

import VestinBG from "assets/images/vesting/bg.gif";

import ConnectModal from "components/ds/Modals/ConnectModal";
import { connectorLocalStorageKey } from "lib/constants";
import useAuth from "hooks/useAuth";
import { Toast } from "components/ds";

interface Props {}

const Layout: React.FC<Props> = ({ children }: any) => {
  const location = useLocation();
  const { account, chainId } = useWeb3React();
  const { login, logout } = useAuth();
  const [openConnectModal, setOpenConnectModal] = useState(false);
  const isMobile = useIsMobile(1340);
  const handleBack = () => {
    if (location.pathname === "/") {
      return VestinBG;
    }
  };

  const handleConnect = () => {
    setOpenConnectModal(true);
  };

  const handleDisconnect = () => {
    logout();
    window.localStorage.removeItem(connectorLocalStorageKey);
  };

  return (
    <>
      <GlobalStyle path={location.pathname} />

      <StyledContainer>
        <Topbar account={account} chainId={chainId} handleConnect={handleConnect} handleDisconnect={handleDisconnect} />
        <Wrapper component="main" back={handleBack()}>
          {location.pathname === "/" ? (
            children
          ) : (
            <Box display="flex" position="relative" pl={isMobile ? 0 : 2} pt={isMobile ? 6 : 9}>
              <Body
                px={isMobile ? 1 : 2}
                pt={isMobile ? 1 : 3}
                flex="1"
                overflow={isMobile ? "unset" : "auto"}
                position="relative"
              >
                {children}
              </Body>
            </Box>
          )}
        </Wrapper>
        <ConnectModal open={openConnectModal} setOpen={setOpenConnectModal} login={login} />
        <Toast />
      </StyledContainer>
    </>
  );
};

const Body = styled(Box)<any>`
  flex: 1;
  height: calc(100% - 24px);
  &::-webkit-scrollbar {
    width: 0px;
  }
`;

const StyledContainer = styled(Box)<any>`
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: black;
  position: relative;
`;

const Wrapper = styled(Box)<any>`
  height: 100%;
  background: ${({ back }) => {
    if (back) return `url(${back})`;
  }};
  background-repeat: no-repeat;
  background-position-y: 50px;
  @media (min-width: 800px) {
    background-position-y: 74px;
  }
  image-rendering: pixelated;
  background-size: ${({ back }) => {
    return "100% 100%";
  }};
  display: flex;
  flex-direction: column;
  flex: 1;
  > div {
    height: -webkit-fill-available;
  }
`;

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
