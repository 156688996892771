import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle<any>`
    html, body, #root {
        ${({ path }) => (path === "/" ? "min-height" : "height")}:100%;
        margin: 0px;
    }
    * {
        font-family: Minecraftia;
        color: white;
    }
    body {
        background: #000A20;
        text-rendering: geometricPrecision;
    }
    button {
        border: none; outline: none;
        cursor: pointer;
    }
    input {
        border: none; outline: none;
        background: transparent;
    }
    #mouse-over-popover {
        >div:nth-of-type(3) {
            border-radius: 0px;
            box-shadow: -4px 4px 0px .5px #FF296D;
            font-size: 16px;
            line-height: 24px;
            overflow: visible;
            &:before {
                content: '';
                position: absolute;
                top: -8px;
                right: 8px;
                width: 4px;
                height: 8px;
                background: #b5f7e0;
            }
            &:after {
                content: '';
                position: absolute;
                top: -4px;
                right: 12px;
                width: 4px;
                height: 4px;
                background: #FF296D;
            }
        }
    }
    #mouse-over-popover1 {
        >div:nth-of-type(3) {
            border-radius: 0px;
            box-shadow: -4px 4px 0px .5px #FF296D;
            font-size: 16px;
            line-height: 24px;
            overflow: visible;
            transform: translate(4px, 6px) !important;
            &:before {
                content: '';
                position: absolute;
                top: -8px;
                right: 8px;
                width: 4px;
                height: 8px;
                background: #b5f7e0;
            }
            &:after {
                content: '';
                position: absolute;
                top: -4px;
                right: 12px;
                width: 4px;
                height: 4px;
                background: #FF296D;
            }
        }
    }
`;

export default GlobalStyle;
