import React from "react";
import { Box } from "@mui/material";

import PrimaryLoader from "assets/images/ds/primaryLoader.svg";
import SecondaryLoader from "assets/images/ds/secondaryLoader.svg";
import PinkLoader from "assets/images/ds/pinkLoader.svg";

interface IconProps {
  variant?: string;
  width?: string;
  height?: string;
}

const LoaderIcon: React.FC<IconProps> = ({ variant = "primary", width = "20", height = "20" }) => {
  const loaderImage = { primary: PrimaryLoader, secondary: SecondaryLoader, pink: PinkLoader }[variant];
  return (
    <Box py={0} px="5px" display="flex" alignItems="center" justifyContent="center">
      <img src={loaderImage} width={width} height={height} alt="loader" />
    </Box>
  );
};

export default LoaderIcon;
