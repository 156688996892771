import React from "react";
import styled from "styled-components";
import { Box } from "@mui/material";

interface SvgProps {
  color?: string;
}

const SvgIcon: React.FC<SvgProps> = ({ color = "#FF296D" }) => {
  return (
    <StyledContainer
      component="svg"
      width="20px"
      height="20px"
      {...{ viewBox: "0 0 20 20" }}
      {...{ xmlns: "http://www.w3.org/2000/svg" }}
    >
      <mask
        id="mask0_1351:9368"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="1"
        y="1"
        width="18"
        height="18"
      >
        <g clipPath="url(#clip0_1351:9368)">
          <path
            d="M15.0003 1.6665V3.33317H16.667V4.99984H18.3337V14.9998H16.667V16.6665H15.0003V18.3332H5.00033V16.6665H3.33366V14.9998H1.66699V4.99984H3.33366V3.33317H5.00033V1.6665H15.0003Z"
            fill="#05D8E8"
          />
        </g>
      </mask>
      <g mask="url(#mask0_1351:9368)">
        <rect x="1.66699" y="1.6665" width="16.6667" height="16.6667" fill="#B5F7E0" />
        <rect x="1.66699" y="1.6665" width="16.6667" height="16.6667" fill="url(#pattern0)" />
      </g>
      <defs>
        <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use xlinkHref="#image0_1351:9368" transform="translate(0.03125 -0.320146) scale(0.015625)" />
        </pattern>
        <clipPath id="clip0_1351:9368">
          <rect width="16.6667" height="16.6667" fill="white" transform="translate(1.66699 1.6665)" />
        </clipPath>
        <image
          id="image0_1351:9368"
          width="60"
          height="64"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAABACAYAAABGHBTIAAABIUlEQVRoBe2aYQ6CMAyFx1E4kdx501tpzfxDJd2SATKoH0mTAsHsvfdRNVkIHDiAAziAAziAAzhQcUBEhpSSGPWqPHbdWwie0ybh63KsVg7SIK1w8NCCNEh74FhpAGmQVjh4aEEapD1wrDSANEgrHDy0IA3SHjhWGkAapBUOHlqQBmkPHCsNIA3SCgcP7d8h7SE0NOAADuDAYQ5ICOEsdcgej7OIzetA8C84J+GO77RfpIcQxKgseChUM90musZ+R2sP5E7XojymsVj3aTTXuHaYmR+G4PnP+E6p6l2zJPyFN0jPvwBXTe8u73CMUXTlFEuVbp+hlcUt69k8oisPmEZ0+i5elWRFm3kLwZ3SzcaTsMnkxosgvQHp5XRtPW+exm9gamFtZwAylAAAAABJRU5ErkJggg=="
        />
      </defs>
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)``;

export default SvgIcon;
