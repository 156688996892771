import React from "react";
import styled from "styled-components";
import { Box } from "@mui/material";

interface AvatarProps {
  imgArr: any;
  round?: boolean;
  width?: string;
  height?: string;
}

const Avatar: React.FC<AvatarProps> = ({ imgArr, round = false, width, height }) => {
  return (
    <>
      {imgArr.length > 1 ? (
        <StyledContainer both={1} round={round ? 1 : 0}>
          <img src={imgArr[1]} alt="" width="100%" height="100%" />
          <img src={imgArr[0]} alt="" width="100%" height="100%" style={{ zIndex: 1 }} />
        </StyledContainer>
      ) : (
        <StyledContainer round={round ? 1 : 0}>
          <img src={imgArr[0]} alt="" width="100%" height="100%" />
        </StyledContainer>
      )}
    </>
  );
};

const StyledContainer = styled(Box)<any>`
  position: relative;
  width: fit-content;
  height: fit-content;
  border-radius: ${({ round }) => (round ? "50%" : "5px")};
  display: flex;
  flex-direction: row-reverse;
  > img {
    width: 32px;
    height: 32px;
    border-radius: ${({ round }) => (round ? "50%" : "5px")};
  }
  ${({ both }) => (!both ? "overflow: hidden" : null)};
  > img:first-of-type {
    ${({ both }) => (both ? "margin-left: -12px" : "")};
  }
`;

export default Avatar;
