import React from "react";
import styled from "styled-components";
import { Box, BoxProps } from "@mui/material";

import AvatarContainer from "components/ds/Containers/AvatarContainer";
import { ExpandMoreIcon } from "components/ds/Icons";
import { Button } from "components/ds";

interface InputFieldProps extends BoxProps {
  variant?: "black" | "white" | "cyan";
  errorMessage?: string;
  showExpand?: boolean;
  placeholder: string;
  icon: any;
  value?: any;
  type?: string;
  onChange?: any;
  buttonActive?: boolean;
  buttonLabel?: string;
  onButtonClick?: any;
  buttonDisabled?: boolean;
}

const InputField: React.FC<InputFieldProps> = ({
  variant = "black",
  errorMessage,
  showExpand = false,
  placeholder,
  icon,
  value,
  type = "text",
  buttonActive = false,
  buttonLabel,
  onChange,
  onButtonClick,
  buttonDisabled = false,
  ...props
}) => {
  return (
    <>
      <StyledContainer variant={variant} buttonactive={buttonActive ? 1 : 0} {...props}>
        <Box flex="10" pt={0.5} pb={0.5} display="flex" alignItems="center">
          <Box padding="2px">
            <AvatarContainer scale={3}>{icon}</AvatarContainer>
          </Box>
          <Box ml={0.5} flex="1" display="flex">
            <input type={type} placeholder={placeholder} value={value} onChange={onChange} />
            {buttonActive && (
              <Button
                title={buttonLabel || "MAX"}
                width="fit-content"
                onClick={onButtonClick}
                disabled={buttonDisabled}
              />
            )}
          </Box>
        </Box>
        {showExpand && (
          <RightValue flex="1" display="flex" justifyContent="flex-end" fontSize="14px" lineHeight="21px">
            <ExpandMoreIcon />
          </RightValue>
        )}
      </StyledContainer>
      {errorMessage && (
        <ErrorMsg padding="4px 8px" fontStyle="italic">
          {errorMessage}
        </ErrorMsg>
      )}
    </>
  );
};

const ErrorMsg = styled(Box)`
  border-top: 1.8px solid #f34a34;
  margin: 0 !important;
  font-size: 16px;
  line-height: 24px;
  color: #ff296d;
  background-color: transparent;
`;

const RightValue = styled(Box)`
  font-family: Courier;
`;

const StyledContainer = styled(Box)<any>`
  padding: ${({ buttonactive }) => (buttonactive ? "4px 0 4px 8px" : "4px 8px")};
  height: 30px;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  border-radius: 2px;
  background: ${({ variant }) => {
    if (variant === "black") return "#232323";
    if (variant === "white") return "#F2F2F2";
    if (variant === "cyan") return "#05D8E8";
  }};
  border: 1px solid ${({ variant }) => (variant === "white" ? "#0178C5" : "transparent")};
  svg {
    width: 20px;
    height: 20px;
    fill: ${({ variant }) => (variant === "white" ? "black" : "white")};
  }
  * {
    color: ${({ variant }) => (variant === "white" ? "black" : "white")};
  }
  input {
    &::placeholder {
      color: white;
    }
    font-size: 16px;
    line-height: 24px;
    width: 100%;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
  }
`;

export default InputField;
