import React from "react";
import styled from "styled-components";
import { Box } from "@mui/material";

interface SvgProps {
  color?: string;
}

const SvgIcon: React.FC<SvgProps> = ({ color = "black" }) => {
  return (
    <StyledContainer
      component="svg"
      width="24"
      height="24"
      {...{ viewBox: "0 0 24 24" }}
      {...{ fill: color }}
      {...{ xmlns: "http://www.w3.org/2000/svg" }}
    >
      <path d="M5.33333 5.33333H4.22222V6.44444H5.33333V5.33333Z" />
      <path d="M6.44444 4.22222H5.33333V5.33333H6.44444V4.22222Z" />
      <path d="M8.66667 3.11111H6.44444V4.22222H8.66667V3.11111Z" />
      <path d="M15.3333 2H8.66667V3.11111H15.3333V2Z" />
      <path d="M17.5556 3.11111H15.3333V4.22222H17.5556V3.11111Z" />
      <path d="M18.6667 4.22222H17.5556V5.33333H18.6667V4.22222Z" />
      <path d="M20.8889 6.44444H19.7778V8.66667H20.8889V6.44444Z" />
      <path d="M19.7778 5.33333H18.6667V6.44444H19.7778V5.33333Z" />
      <path d="M4.22222 6.44444H3.11111V8.66667H4.22222V6.44444Z" />
      <path d="M3.11111 8.66667H2V15.3333H3.11111V8.66667Z" />
      <path d="M4.22222 15.3333H3.11111V17.5556H4.22222V15.3333Z" />
      <path d="M5.33333 17.5556H4.22222V18.6667H5.33333V17.5556Z" />
      <path d="M6.44444 18.6667H5.33333V19.7778H6.44444V18.6667Z" />
      <path d="M8.66667 19.7778H6.44444V20.8889H8.66667V19.7778Z" />
      <path d="M15.3333 20.8889H8.66667V22H15.3333V20.8889Z" />
      <path d="M17.5556 19.7778H15.3333V20.8889H17.5556V19.7778Z" />
      <path d="M18.6667 18.6667H17.5556V19.7778H18.6667V18.6667Z" />
      <path d="M19.7778 17.5556H18.6667V18.6667H19.7778V17.5556Z" />
      <path d="M20.8889 15.3333H19.7778V17.5556H20.8889V15.3333Z" />
      <path d="M22 8.66667H20.8889V15.3333H22V8.66667Z" />
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)``;

export default SvgIcon;
