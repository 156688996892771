import { useTheme, Box, BoxProps, Typography, TypographyProps } from "@mui/material";
import { InfoPopoverIcon } from "components/ds";

type Variant = "S" | "M" | "L";
type Props = BoxProps & {
  name?: string;
  value?: any;
  extra?: any;
  popoverProps?: any;
  extraProps?: any;
  valueProps?: TypographyProps;
  nameProps?: TypographyProps;
  align?: "left" | "center" | "right";
  disabled?: boolean;
  extraPosition?: "inline" | "vertical";
  Icon?: any;
  variant?: Variant;
};

const ParameterCard: React.FC<Props> = ({
  name,
  value,
  extra,
  popoverProps,
  valueProps,
  nameProps,
  extraProps,
  align = "left",
  extraPosition = "inline",
  disabled = false,
  Icon = null,
  variant = "S",
  ...props
}) => {
  const theme = useTheme();
  return (
    <Box
      flex="1"
      display="flex"
      flexDirection="column"
      alignItems={{ left: "flex-start", center: "center", right: "flex-end" }[align]}
      {...props}
    >
      <Box display="flex">
        <Typography
          color={disabled ? "dustyGray30" : "primary60"}
          variant="h5"
          fontFamily="'Minecraftia Bold'"
          align={align}
          {...nameProps}
        >
          {name}
        </Typography>
        {popoverProps ? (
          <Box width="15px" height="15px" ml={0.5}>
            <InfoPopoverIcon
              color={disabled ? theme.palette.dustyGray30 : theme.palette.primary60}
              width={20}
              height={20}
              {...popoverProps}
            />
          </Box>
        ) : Icon ? (
          <Box ml={0.5}>{Icon}</Box>
        ) : null}
      </Box>
      {value !== undefined && (
        <Typography variant={valueFontVariants[variant]} color="primary10" align={align} {...valueProps}>
          {value}
          {extra && extraPosition === "inline" ? (
            <Typography
              component="span"
              variant="body2"
              color="primary10"
              fontFamily="Minecraftia Italic"
              align={align}
              {...extraProps}
            >
              ({extra})
            </Typography>
          ) : null}
        </Typography>
      )}
      {extra && extraPosition === "vertical" ? (
        <Typography component="span" variant="body2" color="primary10" fontFamily="Minecraftia Italic" {...extraProps}>
          {extra}
        </Typography>
      ) : null}
    </Box>
  );
};
const valueFontVariants: { [key in Variant]: TypographyProps["variant"] } = { S: "body2", M: "h4", L: "h2" };
export default ParameterCard;
