import React from "react";
import styled from "styled-components";
import { Box } from "@mui/material";

interface SvgProps {
  color?: string;
  width?: string;
  height?: string;
}

const SvgIcon: React.FC<SvgProps> = ({ color = "black", width = "20", height = "20" }) => {
  return (
    <StyledContainer
      component="svg"
      width={width}
      height={height}
      {...{ viewBox: `0 0 ${width} ${height}` }}
      {...{ fill: color }}
      {...{ xmlns: "http://www.w3.org/2000/svg" }}
    >
      <path d="M0 0V2.01058H2.03834V3.9859H0V5.95137L4.05517 6.00725V0H0Z" fill="#242424" />
      <path
        d="M6.03566 8.02566H0V9.99804H8.06132V2.01058H14.0833V3.9859H10.0418V9.99804H20V0.00195789H18.0826V8.02664H12.0596V6.00823H16.0619V0.000978947H6.03566V8.02566Z"
        fill="#242424"
      />
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)``;

export default SvgIcon;
