import { SVGAttributes } from "react";
import { DefaultTheme } from "styled-components";
import { SpaceProps } from "styled-system";

export interface SvgProps extends SVGAttributes<HTMLOrSVGElement>, SpaceProps {
  theme?: DefaultTheme;
  spin?: boolean;
}

export enum ConnectorNames {
  Injected = "injected",
  WalletConnect = "walletconnect",
}

export type Login = (connectorId: ConnectorNames) => void;

export interface Config {
  id: number;
  name: string;
  icon: any;
  connectorId: ConnectorNames;
}

export interface PopupProps {
  open: boolean;
  setOpen: (open: boolean | ((prevOpen: boolean) => boolean)) => void;
}
