import React from "react";
import styled from "styled-components";
import { Box } from "@mui/material";

import { CircleOutlineIcon } from "components/ds/Icons";

interface ButtonIconProps {
  scale?: Number;
  outline?: boolean;
}

const ButtonIcon: React.FC<ButtonIconProps> = ({ scale = 0, outline = false }) => {
  return (
    <StyledContainer scale={scale} outline={outline}>
      <CircleOutlineIcon />
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)<any>`
  width: ${({ scale }) => {
    if (scale === 0) return 12;
    if (scale === 1) return 16;
    if (scale === 2) return 20;
    if (scale === 3) return 24;
  }}px;
  height: ${({ scale }) => {
    if (scale === 0) return 12;
    if (scale === 1) return 16;
    if (scale === 2) return 20;
    if (scale === 3) return 24;
  }}px;
  padding: 1.67px;
  position: relative;
  background: ${({ outline }) => (outline ? "transparent" : "#05D8E8")};
  transition: 0.3s;
  > svg {
    transition: 0.3s;
    width: 100%;
    height: 100%;
    fill: ${({ outline }) => (outline ? "#FF296D" : "white")};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &:hover {
    background: #6ad4f1;
    > svg {
      fill: white;
    }
    box-shadow: 4px 3px 0px 1px #ff296d;
  }
  &:active {
    background: #b5f7e0;
    > svg {
      fill: #0178c5;
    }
  }
  &:disabled {
    background: #0178c5;
    > svg {
      fill: #c7c7c7;
    }
    cursor: not-allowed;
  }
`;

export default ButtonIcon;
