import * as body from "./style-dictionary/build/body";
import * as heading from "./style-dictionary/build/heading";

const { regularH1, regularH2, regularH3, regularH4, regularH5, regularH6, regularH7 } = heading;
const typography = {
  h1: {
    fontFamily: "'Minecraftia'",
    fontWeight: regularH1.fontWeight,
    fontSize: regularH1.fontSize,
    lineHeight: regularH1.lineHeight,
    textDecoration: regularH1.textDecoration,
  },
  h2: {
    fontFamily: "'Minecraftia'",
    fontWeight: regularH2.fontWeight,
    fontSize: regularH2.fontSize,
    lineHeight: regularH2.lineHeight,
    textDecoration: regularH2.textDecoration,
  },
  h3: {
    fontFamily: "'Minecraftia'",
    fontWeight: regularH3.fontWeight,
    fontSize: regularH3.fontSize,
    lineHeight: regularH3.lineHeight,
    textDecoration: regularH3.textDecoration,
  },
  h4: {
    fontFamily: "'Minecraftia'",
    fontWeight: regularH4.fontWeight,
    fontSize: regularH4.fontSize,
    lineHeight: regularH4.lineHeight,
    textDecoration: regularH4.textDecoration,
  },
  h5: {
    fontFamily: "'Minecraftia'",
    fontWeight: regularH5.fontWeight,
    fontSize: regularH5.fontSize,
    lineHeight: regularH5.lineHeight,
    textDecoration: regularH5.textDecoration,
  },
  h6: {
    fontFamily: "'Minecraftia'",
    fontWeight: regularH6.fontWeight,
    fontSize: regularH6.fontSize,
    lineHeight: regularH7.lineHeight,
    textDecoration: regularH6.textDecoration,
  },
  h7: {
    fontFamily: "'Minecraftia'",
    fontWeight: regularH7.fontWeight,
    fontSize: regularH7.fontSize,
    lineHeight: regularH7.lineHeight,
    textDecoration: regularH7.textDecoration,
  },
  body1: {
    // large
    fontFamily: "'Minecraftia'",
    fontWeight: body.largeRegular.fontWeight,
    fontSize: body.largeRegular.fontSize,
    lineHeight: body.largeRegular.lineHeight,
    textDecoration: body.largeRegular.textDecoration,
  },
  body2: {
    // medium
    fontFamily: "'Minecraftia'",
    fontWeight: body.mediumDefaultRegular.fontWeight,
    fontSize: body.mediumDefaultRegular.fontSize,
    lineHeight: body.mediumDefaultRegular.lineHeight,
    textDecoration: body.mediumDefaultRegular.textDecoration,
  },
  body3: {
    // small
    fontFamily: "'Minecraftia'",
    fontWeight: body.smallDefaultRegular.fontWeight,
    fontSize: body.smallDefaultRegular.fontSize,
    lineHeight: body.smallDefaultRegular.lineHeight,
    textDecoration: body.smallDefaultRegular.textDecoration,
  },
  boldFontFamily: "'Minecraftia Bold'",
  italicFontFamily: "'Minecraftia Italic'",
};

export default typography;
