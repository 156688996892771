import { useState, useEffect } from "react";

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
};

export const useIsMobile = (defaultWidth = 840) => {
  const [isMobile, setIsMobile] = useState(false);
  const screenWidth = useWindowDimensions().width;

  useEffect(() => {
    screenWidth > defaultWidth ? setIsMobile(false) : setIsMobile(true);
  }, [screenWidth]);

  return isMobile;
};

export default useWindowDimensions;
