import { explorers } from "lib/constants";
function wrapErrorMessage(errMsg: string) {
  return `Transaction failed. ${errMsg ? `${errMsg} ` : ""}Try weening again.`;
}

export const formatTxErrorMessage = (txError?: any): string | null | undefined => {
  if (!txError) {
    return wrapErrorMessage("");
  }

  // ignore MM reject error
  if (txError.code === 4001) {
    return null;
  }

  if (txError.error) {
    const { error } = txError;
    if (error.message && txError.reason) {
      return wrapErrorMessage(`${error.message}; ${txError.reason}`);
    }
    if (error.message) {
      return wrapErrorMessage(error.message);
    }
    if (error.originalError?.message) {
      return wrapErrorMessage(error.originalError?.message);
    }
  }
  if (txError.message) {
    return wrapErrorMessage(txError.message);
  }
};

export const getExplorerUrl = (txHash: string, chainId: number | undefined) => {
  if (!chainId) return "";
  return `${explorers[chainId]}/tx/${txHash}`;
};
