import React from "react";
import styled from "styled-components";
import { Box } from "@mui/material";

interface SvgProps {
  color?: string;
}

const SvgIcon: React.FC<SvgProps> = ({ color = "#FF296D" }) => {
  return (
    <StyledContainer
      component="svg"
      width="20px"
      height="20px"
      {...{ viewBox: "0 0 20 20" }}
      {...{ fill: color }}
      {...{ xmlns: "http://www.w3.org/2000/svg" }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.66699 3.33337H5.00033V5.00004H3.33366V6.66671V8.33337V10H1.66699V11.6667V13.3334V15H3.33366H5.00033V16.6667H6.66699V15H5.00033V13.3334H6.66699H8.33366V15H10.0003H11.667V13.3334H13.3337H15.0003V15H13.3337V16.6667H15.0003V15H16.667H18.3337V13.3334V11.6667V10H16.667V8.33337V6.66671V5.00004H15.0003V3.33337H13.3337V5.00004H15.0003V6.66671H13.3337H11.667V5.00004H10.0003H8.33366V6.66671H6.66699H5.00033V5.00004H6.66699V3.33337ZM8.33366 11.6667V10H6.66699V11.6667H8.33366ZM11.667 11.6667H13.3337V10H11.667V11.6667Z"
        fill="#FF296D"
      />
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)``;

export default SvgIcon;
