import * as React from "react";
import { Box, Popover } from "@mui/material";
import styled from "styled-components";

import { ExternalLinkIcon } from "components/ds/Icons";

interface PopoverProps {
  title: string;
  description: string;
  url?: string;
  children: (props: any) => React.ReactElement;
}

const PopoverComponent: React.FC<PopoverProps> = ({ title, description, url, children }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <>
      {children && typeof children === "function"
        ? children({
            "aria-owns": open ? "mouse-over-popover" : undefined,
            "aria-haspopup": "true",
            onMouseEnter: handlePopoverOpen,
            onMouseLeave: handlePopoverClose,
          })
        : null}
      <Popover
        id="mouse-over-popover1"
        style={{ pointerEvents: "none" }}
        PaperProps={{
          style: { width: 200 },
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <PopoverContent>
          {title && (
            <Box color="#003782" fontFamily="Minecraftia Bold" display="flex" alignItems="center">
              {title}
              {url && (
                <Box component="span" ml={1} mt="5px">
                  <ExternalLinkIcon color="#003782" width={20} height={20} />
                </Box>
              )}
            </Box>
          )}
          {description && (
            <Box color="#242424" mt={1}>
              {description}
            </Box>
          )}
        </PopoverContent>
      </Popover>
    </>
  );
};

const PopoverContent = styled(Box)`
  background: #b5f7e0;
  box-shadow: -4px 4px 0px 0.5px #ff296d;
  padding: 8px;
  > div {
    font-size: 16px;
    line-height: 20px;
  }
`;

export default PopoverComponent;
