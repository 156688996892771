import { createTheme } from "@mui/material/styles";

import typography from "./typography";

import * as colors from "./style-dictionary/build/color";
import * as elevations from "./style-dictionary/build/elevation";

const { elevation4, elevation8, elevation16 } = elevations;
const shadows: any = [
  `${elevation4.x}px ${elevation4.y}px ${elevation4.blur}px ${elevation4.spread}px ${elevation4.color}`,
  `${elevation8.x}px ${elevation8.y}px ${elevation8.blur}px ${elevation8.spread}px ${elevation8.color}`,
  `${elevation16.x}px ${elevation16.y}px ${elevation16.blur}px ${elevation16.spread}px ${elevation16.color}`,
];

const theme = createTheme({
  shadows,
  typography,
  palette: colors as any,
});

export default theme;
