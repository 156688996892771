import React from "react";
import styled from "styled-components";
import { Box } from "@mui/material";

interface SvgProps {
  color?: string;
}

const SvgIcon: React.FC<SvgProps> = ({ color = "#FF296D" }) => {
  return (
    <StyledContainer
      component="svg"
      width="20"
      height="15"
      {...{ viewBox: "0 0 20 15" }}
      {...{ fill: color }}
      {...{ xmlns: "http://www.w3.org/2000/svg" }}
    >
      <path d="M3.52937 2.35292H2.35292V3.52937H3.52937V2.35292Z" />
      <path d="M8.23521 2.35292H4.70583V3.52937H8.23521V2.35292Z" />
      <path d="M9.41167 3.52958H8.23521V4.70583H9.41167V3.52958Z" />
      <path d="M8.23521 4.70583H2.35292V5.88229H8.23521V4.70583Z" />
      <path d="M9.41167 5.8825L8.23521 5.88229V7.05875H9.41167V5.8825Z" />
      <path d="M8.23521 7.05875H2.35292V8.23521H8.23521V7.05875Z" />
      <path d="M9.41167 8.23542L8.23521 8.23521V9.41187H9.41167V8.23542Z" />
      <path d="M10.5881 1.17667H9.41187V2.35292H10.5881V1.17667Z" />
      <path d="M10.5881 0V1.17667H18.8237V9.41187H12.941V10.5883H10.5881V11.7648H9.41187V10.5883H7.05896V9.41187H1.17625V1.17667H9.41187V0H0V12.9412H8.23521V14.1177H11.7648V12.9412H20V0H10.5881Z" />
      <path d="M17.6471 2.35292H11.7648V3.52937H17.6471V2.35292Z" />
      <path d="M11.7646 3.52958H10.5881V4.70583H11.7646V3.52958Z" />
      <path d="M17.6471 4.70583H11.7646L11.7648 5.88229H17.6471V4.70583Z" />
      <path d="M11.7646 5.8825H10.5881V7.05875H11.7646V5.8825Z" />
      <path d="M17.6471 7.05875H11.7646L11.7648 8.23521H17.6471V7.05875Z" />
      <path d="M11.7646 8.23542H10.5881V9.41187H11.7646V8.23542Z" />
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)``;

export default SvgIcon;
