import React from "react";
import styled from "styled-components";
import { Box } from "@mui/material";

interface SvgProps {
  color?: string;
}

const SvgIcon: React.FC<SvgProps> = ({ color = "#FF296D" }) => {
  return (
    <StyledContainer
      component="svg"
      width="16"
      height="20"
      {...{ viewBox: "0 0 16 20" }}
      {...{ fill: color }}
      {...{ xmlns: "http://www.w3.org/2000/svg" }}
    >
      <path d="M8.88889 2.22222H11.1111V0C8.88889 0 6.66667 0 4.44444 0V2.22222C5.92593 2.22222 7.40741 2.22222 8.88889 2.22222Z" />
      <path d="M13.3333 8.88889C13.3333 6.66667 13.3333 4.44444 13.3333 2.22222H11.1111C11.1111 4.44444 11.1111 6.66667 11.1111 8.88889C8.88889 8.88889 6.66667 8.88889 4.44444 8.88889C4.44444 6.66667 4.44444 4.44444 4.44444 2.22222H2.22222C2.22222 4.44444 2.22222 6.66667 2.22222 8.88889H0C0 12.5926 0 16.2963 0 20C5.18518 20 10.3704 20 15.5556 20C15.5556 16.2963 15.5556 12.5926 15.5556 8.88889H13.3333ZM13.3333 13.3333C13.3333 14.8148 13.3333 16.2963 13.3333 17.7778C9.62963 17.7778 5.92593 17.7778 2.22222 17.7778C2.22222 15.5556 2.22222 13.3333 2.22222 11.1111C5.92593 11.1111 9.62963 11.1111 13.3333 11.1111V13.3333Z" />
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)``;

export default SvgIcon;
