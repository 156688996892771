import React from "react";
import { Box, Modal } from "@mui/material";
import styled from "styled-components";
import _ from "lodash";

import { PopupProps } from "lib/typings";
import { Button } from "components/ds/Buttons";

import { connectorLocalStorageKey } from "lib/constants";

import Web3 from "web3";

import Icon1 from "assets/images/modal/1.svg";
import Icon2 from "assets/images/modal/2.svg";
import { Config, ConnectorNames, Login } from "lib/typings";

export const connections: Config[] = [
  {
    id: 0,
    name: "Metamask",
    icon: Icon1,
    connectorId: ConnectorNames.Injected,
  },
  {
    id: 1,
    name: "WalletConnect",
    icon: Icon2,
    connectorId: ConnectorNames.WalletConnect,
  },
];

interface ModalProps extends PopupProps {
  login: Login;
}

declare let window: any;

const ConnectModal: React.FC<ModalProps> = ({ open, setOpen, login }) => {
  const loadWeb3 = async () => {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
      window.ethereum.enable();
    }
  };

  const handleConnect = async (i: number) => {
    login(connections[i].connectorId);
    window.localStorage.setItem(connectorLocalStorageKey, connections[i].connectorId);
    await loadWeb3();
    setOpen(false);
  };

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <StyledContainer>
        {_.map(connections, (each, i) => (
          <Box key={i} display="flex" flexDirection="column" alignItems="center">
            <Box>
              <img src={each.icon} alt="" />
            </Box>
            <Box color="#232323" padding="12px 0">
              {each.name}
            </Box>
            <ButtonContainer>
              <Button title={`Connect with ${each.name}`} onClick={() => handleConnect(i)} />
            </ButtonContainer>
          </Box>
        ))}
      </StyledContainer>
    </Modal>
  );
};

const ButtonContainer = styled(Box)`
  padding: 16px;
  width: -webkit-fill-available;
  > button {
    width: 100%;
  }
`;

const StyledContainer = styled(Box)<any>`
  padding: 8px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #b5f7e0;
  min-width: 384px;
  max-height: calc(100% - 50px);
  overflow-y: auto;
  max-width: calc(100% - 32px);
  box-shadow: -4px 4px 0px 0.5px #ff296d;
  > div + div {
    margin-top: 8px;
  }
`;

export default ConnectModal;
