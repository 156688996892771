import React from "react";
import styled from "styled-components";
import { Box } from "@mui/material";

interface SvgProps {
  color?: string;
}

const SvgIcon: React.FC<SvgProps> = ({ color = "red" }) => {
  return (
    <StyledContainer
      component="svg"
      width="20px"
      height="20px"
      {...{ viewBox: "0 0 20 20" }}
      {...{ fill: color }}
      {...{ xmlns: "http://www.w3.org/2000/svg" }}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.3337 1.66699V3.33366H11.667V5.00033H10.0003V6.66699H8.33366V8.33366H6.66699V10.0003H5.00033V8.33366H3.33366V15.0003H1.66699V18.3337H5.00033V16.667H11.667V15.0003H10.0003V13.3337H11.667V11.667H13.3337V10.0003H15.0003V8.33366H16.667V6.66699H18.3337V1.66699H13.3337ZM16.667 6.66699H15.0003V8.33366H13.3337V10.0003H11.667V11.667H10.0003V13.3337H6.66699V10.0003H8.33366V8.33366H10.0003V6.66699H11.667V5.00033H13.3337V3.33366H16.667V6.66699Z"
      />
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)``;

export default SvgIcon;
