import React from "react";
import styled from "styled-components";
import { Box } from "@mui/material";

interface SvgProps {
  color?: string;
}

const SvgIcon: React.FC<SvgProps> = ({ color = "black" }) => {
  return (
    <StyledContainer
      component="svg"
      width="18"
      height="18"
      {...{ viewBox: "0 0 18 18" }}
      {...{ fill: color }}
      {...{ xmlns: "http://www.w3.org/2000/svg" }}
    >
      <path d="M11.7781 2.51836H9.92625V4.37021H11.7781V2.51836Z" />
      <path d="M11.7781 9.92576H13.63V4.37021H11.7781V9.92576Z" />
      <path d="M11.7781 9.92576H9.92625V11.7776H11.7781V9.92576Z" />
      <path d="M13.63 11.7776H11.7781V13.6295H13.63V11.7776Z" />
      <path d="M15.4818 13.6295H13.63V15.4813H15.4818V13.6295Z" />
      <path d="M17.3337 15.4813H15.4818V17.3332H17.3337V15.4813Z" />
      <path d="M2.51884 4.37021H0.666992V9.92576H2.51884V4.37021Z" />
      <path d="M4.3707 9.92576H2.51884V11.7776H4.3707V9.92576Z" />
      <path d="M9.92625 2.51836V0.666504H4.3707V2.51836H9.92625Z" />
      <path d="M4.3707 11.7776V13.6295H9.92625V11.7776H4.3707Z" />
      <path d="M4.3707 2.51836H2.51884V4.37021H4.3707V2.51836Z" />
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)``;

export default SvgIcon;
