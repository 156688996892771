import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Web3ReactProvider } from "@web3-react/core";
import { ThemeProvider } from "@mui/material/styles";

import Web3ProviderNetwork from "components/Wallet/Web3ProviderNetwork";
import Web3ReactManager from "components/Wallet/Web3Reactmanager";
import theme from "lib/theme";

import Layout from "./components/Layout";
import Claim from "pages/Claim";

import { getLibrary } from "lib/config";

const App = () => {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <Web3ProviderNetwork getLibrary={getLibrary}>
        <Web3ReactManager>
          <ThemeProvider theme={theme}>
            <Router>
              <Switch>
                <Layout>
                  {/* <Route exact path="/"> */}
                  <Claim />
                  {/* </Route> */}
                </Layout>
              </Switch>
            </Router>
          </ThemeProvider>
        </Web3ReactManager>
      </Web3ProviderNetwork>
    </Web3ReactProvider>
  );
};

export default App;
