import { useWeb3React as useWeb3ReactCore } from "@web3-react/core";
import { useEffect } from "react";

import { injected } from "lib/config";

/**
 * Use for network and injected - logs user in
 * and out after checking what network theyre on
 */
function useInactiveListener(suppress = false) {
  const { active, error, activate } = useWeb3ReactCore(); // specifically using useWeb3React because of what this hook does

  useEffect(() => {
    const { ethereum } = window;

    // @ts-ignore TYPE NEEDS FIXING
    if (ethereum && ethereum.on && !active && !error && !suppress) {
      const handleChainChanged = () => {
        // eat errors
        activate(injected, undefined, true).catch(error => {
          console.error("Failed to activate after chain changed", error);
        });
      };

      const handleAccountsChanged = (accounts: string[]) => {
        if (accounts.length > 0) {
          // eat errors
          activate(injected, undefined, true).catch(error => {
            console.error("Failed to activate after accounts changed", error);
          });
        }
      };

      // @ts-ignore TYPE NEEDS FIXING
      ethereum.on("chainChanged", handleChainChanged);
      // @ts-ignore TYPE NEEDS FIXING
      ethereum.on("accountsChanged", handleAccountsChanged);

      return () => {
        // @ts-ignore TYPE NEEDS FIXING
        if (ethereum.removeListener) {
          // @ts-ignore TYPE NEEDS FIXING
          ethereum.removeListener("chainChanged", handleChainChanged);
          // @ts-ignore TYPE NEEDS FIXING
          ethereum.removeListener("accountsChanged", handleAccountsChanged);
        }
      };
    }
    return undefined;
  }, [active, error, suppress, activate]);
}

export default useInactiveListener;
