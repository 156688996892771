import React from "react";
import styled from "styled-components";
import { Box } from "@mui/material";

interface SvgProps {
  color?: string;
}

const SvgIcon: React.FC<SvgProps> = ({ color = "#FF296D" }) => {
  return (
    <StyledContainer
      component="svg"
      width="18"
      height="16"
      {...{ viewBox: "0 0 18 16" }}
      {...{ fill: color }}
      {...{ xmlns: "http://www.w3.org/2000/svg" }}
    >
      <path d="M16.292 10.9167V6.75H14.2087V4.66667H12.1253V2.58333H10.042V4.66667H7.95866V2.58333H5.87533V4.66667H3.79199V6.75H1.70866V10.9167H0.666992V13H2.75033V10.9167H3.79199V15.0833H7.95866V13H5.87533V10.9167H12.1253V13H10.042V15.0833H14.2087V10.9167H15.2503V13H17.3337V10.9167H16.292ZM7.95866 8.83333H5.87533V6.75H7.95866V8.83333ZM12.1253 8.83333H10.042V6.75H12.1253V8.83333Z" />
      <path d="M5.87533 0.5H3.79199V2.58333H5.87533V0.5Z" />
      <path d="M14.2087 0.5H12.1253V2.58333H14.2087V0.5Z" />
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)``;

export default SvgIcon;
