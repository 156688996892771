/**
 * sets attribute/s on a BehaviourSubject object while keeping the rest
 */
export const setState = (subject: any, attrs: any) => {
  const subjectValue = subject.getValue();
  subject.next({
    ...(subjectValue ?? {}),
    ...(attrs ?? {}),
  });
};
