import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Box, BoxProps } from "@mui/material";

interface ButtonGroupProps extends BoxProps {
  children?: any;
  vertical?: boolean;
  gridAmount?: Number;
  first?: Boolean;
}

const ButtonGroup: React.FC<ButtonGroupProps> = ({
  children,
  vertical = false,
  first = false,
  gridAmount = 0,
  ...props
}) => {
  return (
    <StyledContainer
      flexDirection={vertical ? "column" : "row"}
      vertical={vertical ? 1 : 0}
      amount={gridAmount === 0 ? 1 : gridAmount}
      first={first ? 1 : 0}
      {...props}
    >
      {children}
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)<any>`
  display: initial;
  @media (min-width: 700px) {
    display: ${({ amount }) => (amount === 0 ? "flex" : "grid")};
  }
  grid-template-columns: ${({ amount }) => Array(amount).fill("auto").join(" ")};
  grid-gap: 10px;
  > button {
    width: ${({ amount }) => 100 / amount}%;
  }
  > button:first-of-type {
    ${({ first }) => first && "grid-column: 1 / span 2; grid-row: 2;"}
  }
  > button + button {
    ${({ vertical, amount }) => amount === 0 && (!vertical ? "margin-left: 8px" : "margin-top: 8px")};
  }
`;

ButtonGroup.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ButtonGroup;
