import React from "react";
import { useTheme, styled, Box, BoxProps } from "@mui/material";
import { LoaderIcon } from "components/ds/Icons";

type Variant = "primary" | "secondary" | "danger";

interface ButtonProps extends BoxProps {
  title: string;
  Icon?: any;
  disabled?: boolean;
  align?: "left" | "center" | "right";
  iconAlign?: "start" | "end";
  onClick?: () => void;
  active?: boolean;
  variant?: Variant;
  width?: any;
  height?: any;
  loading?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  onClick,
  title,
  Icon,
  disabled = false,
  align = "center",
  iconAlign = "start",
  active = false,
  variant = "primary",
  loading = false,
  ...containerProps
}) => {
  const theme = useTheme();
  const StyledButton = { primary: PrimaryButton, secondary: SecondaryButton, danger: DangerButton }[variant];

  return (
    <StyledButton
      onClick={onClick}
      component="button"
      disabled={disabled}
      width="160px"
      align={align}
      hasicon={Icon ? 1 : 0}
      active={active ? 1 : 0}
      padding={theme.spacing(1, 2)}
      {...containerProps}
    >
      {loading ? (
        <LoaderIcon variant={variant} />
      ) : (
        <StartIcon active={iconAlign === "start" ? "flex" : "none"}>{Icon && <Icon />}</StartIcon>
      )}
      <Label ml={Icon ? 0.5 : 0}>{title}</Label>
      <EndIcon active={iconAlign === "end" ? "flex" : "none"}>{Icon && <Icon />}</EndIcon>
    </StyledButton>
  );
};

const StartIcon = styled(Box)<any>`
  margin: 2px;
  display: ${({ active }) => active};
  align-items: center;
  justify-content: center;
  > svg {
    width: 20px;
    height: 20px;
  }
`;

const EndIcon = styled(Box)<any>`
  margin: 2px;
  display: ${({ active }) => active};
  align-items: center;
  justify-content: center;
  > svg {
    width: 20px;
    height: 20px;
  }
`;

const Label = styled(Box)``;

const PrimaryButton = styled(Box)<any>`
  display: flex;
  font-size: 16px;
  background: #05d8e8;
  justify-content: ${({ align }) => {
    if (align === "left") return "flex-start";
    if (align === "center") return "center";
    if (align === "right") return "flex-end";
  }};
  align-items: center;
  transition: 0.3s;
  &:hover {
    background: #6ad4f1;
    box-shadow: -4px 4px 0px 0.5px #ff296d;
    transform: translate(4px, -4px);
    ${Label} {
      color: #ff296d;
    }
    svg {
      fill: #ff296d;
    }
  }
  &:disabled {
    ${Label} {
      color: #ffb1b9;
    }
    transform: none;
    background: #0178c5;
    &:hover {
      opacity: 0.8;
    }
    cursor: not-allowed;
    box-shadow: none;
  }
  ${({ active }) => {
    if (active)
      return `
        ${Label} {
            color: #0178C5 !important;
        }
        svg {
            fill: #0178C5 !important;
        }
        background: #B5F7E0 !important;
        `;
  }};
  ${Label} {
    transform: translate(0px, 1px);
    transition: 0.3s;
    color: #3c0006;
    line-height: 24px;
  }
  svg {
    fill: #3c0006;
  }
`;

const SecondaryButton = styled(PrimaryButton)<any>`
  background: #017ac8;
  border: 1px solid #f5fdf9;
  &:hover {
    background: #003782;
    border: 1px solid #f5fdf9;
  }
  &:disabled {
    ${Label} {
      color: #b4b4b4;
    }
    background: #003782;
    border: 1px solid #b4b4b4;
  }
  ${Label} {
    color: #f5fdf9;
  }
  svg {
    fill: #f5fdf9;
  }
`;

const DangerButton = styled(PrimaryButton)<any>`
  background: #ff296d;
  border: 1px solid #f5fdf9;
  &:hover {
    background: #ff296d;
    border: 1px solid #f5fdf9;
  }

  ${Label} {
    color: #05d8e8;
  }
`;

export default Button;
