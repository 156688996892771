import React from "react";
import styled from "styled-components";
import { Box } from "@mui/material";

interface BadgeProps {
  variant?: "purple" | "red" | "yellow" | "black" | "blue" | "green";
  title: string;
}

const Badge: React.FC<BadgeProps> = ({ variant = "black", title }) => {
  return <StyledContainer variant={variant}>{title}</StyledContainer>;
};

const StyledContainer = styled(Box)<any>`
  font-family: Roboto;
  font-weight: 500;
  font-size: 50px;
  padding: 48px 10px 48px 116px;
  color: ${({ variant }) => {
    if (variant === "purple") return "white";
    if (variant === "red") return "white";
    if (variant === "yellow") return "black";
    if (variant === "black") return "white";
    if (variant === "blue") return "white";
    if (variant === "green") return "white";
  }};
  background-color: ${({ variant }) => {
    if (variant === "purple") return "#7547DC";
    if (variant === "red") return "#F34A34";
    if (variant === "yellow") return "#F5BE23";
    if (variant === "black") return "#3F3F3F";
    if (variant === "blue") return "#2E84FF";
    if (variant === "green") return "#22AA61";
  }};
`;

export default Badge;
