import React from "react";
import styled from "styled-components";
import { Box } from "@mui/material";

interface ButtonProps {
  title: string;
  Icon?: any;
  disabled?: boolean;
  iconAlign?: "start" | "end";
  small?: boolean;
  onClick?: () => void;
}

const FitButton: React.FC<ButtonProps> = ({
  onClick,
  title,
  Icon,
  disabled = false,
  iconAlign = "start",
  small = false,
}) => {
  return (
    <StyledContainer
      component="button"
      disabled={disabled}
      width="fit-content"
      bgcolor="#05D8E8"
      onClick={onClick}
      hasicon={Icon ? 1 : 0}
      small={small ? 1 : 0}
    >
      <StartIcon active={iconAlign === "start" ? "flex" : "none"}>{Icon && <img src={Icon} alt="" />}</StartIcon>
      <Box ml={Icon ? 0.5 : 0}>{title}</Box>
      <EndIcon active={iconAlign === "end" ? "flex" : "none"}>{Icon && <img src={Icon} alt="" />}</EndIcon>
    </StyledContainer>
  );
};

const StartIcon = styled(Box)<any>`
  margin: 1.67px;
  display: ${({ active }) => active};
  > svg {
    width: 20px;
    height: 20px;
    fill: #ff296d;
  }
`;
const EndIcon = styled(Box)<any>`
  margin: 1.67px;
  display: ${({ active }) => active};
  > svg {
    width: 20px;
    height: 20px;
    fill: #ff296d;
  }
`;

const StyledContainer = styled(Box)<any>`
  padding: ${({ small }) => (small ? "10px 8px 10px 8px" : "10px 16px 10px 16px")};
  display: flex;
  justify-content: center;
  transition: 0.3s;
  &:hover {
    background: #6ad4f1;
    box-shadow: -4px 4px 0px 0.5px #ff296d;
    transform: translate(4px, -4px);
  }
  &:disabled {
    > div:nth-of-type(2) {
      color: #c7c7c7;
    }
    background: #0178c5;
    cursor: not-allowed;
    box-shadow: none;
  }
  &:active {
    > div:nth-of-type(2) {
      color: #0178c5;
    }
    svg {
      fill: #0178c5;
    }
    background: #b5f7e0;
  }
  > div:nth-of-type(2) {
    transform: translate(0px, 1px);
    transition: 0.3s;
    color: #ff296d;
    font-size: 16px;
    line-height: 24px;
  }
`;

export default FitButton;
