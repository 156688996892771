export const secondsToDHMS = (start: number, end: number) => {
  let delta = (end - start) / 1000;
  const dDisplay = Math.floor(delta / 86400);
  delta -= dDisplay * 86400;
  const hDisplay = Math.floor(delta / 3600) % 24;
  delta -= hDisplay * 3600;
  const mDisplay = Math.floor(delta / 60) % 60;
  delta -= mDisplay * 60;
  const sDisplay = Math.floor(delta % 60);
  return {
    d: Math.abs(dDisplay),
    h: Math.abs(hDisplay),
    m: Math.abs(mDisplay),
    s: Math.abs(sDisplay),
  };
};

/**
 * start, end - timestamps in seconds
 */
export const calcTimeProgressPercentage = (start: number, end: number) => {
  const now = new Date().getTime() / 1000;
  if (now >= end) return 100;
  const total = end - start;
  const elapsed = now - start;
  return (elapsed / total) * 100;
};
