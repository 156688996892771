import React from "react";
import styled from "styled-components";
import { Box } from "@mui/material";

import AvatarContainer from "components/ds/Containers/AvatarContainer";
import { CircleOutlineIcon } from "../Icons";

interface CardProps {
  header: string;
  content: string;
}

const Card: React.FC<CardProps> = ({ header, content }) => {
  return (
    <StyledContainer>
      <Box bgcolor="black" display="flex" justifyContent="center">
        <AvatarContainer scale={7}>
          <CircleOutlineIcon />
        </AvatarContainer>
      </Box>
      <CardHeader bgcolor="#B21640" color="black" padding="6px 8px" fontSize="14px" lineHeight="28px">
        {header}
      </CardHeader>
      <CardBody bgcolor="black" padding={1} display="flex" flexDirection="column">
        <Box
          flex="1"
          minHeight="246px"
          padding="0 8px 8px 8px"
          bgcolor="#FF296D"
          color="white"
          fontSize="16px"
          lineHeight="24px"
        >
          {content}
        </Box>
        <ActionButton component="button">Actions</ActionButton>
      </CardBody>
    </StyledContainer>
  );
};

const ActionButton = styled(Box)`
  background: #05d8e8;
  color: #ff296d;
  height: 40px;
  font-size: 16px;
  line-height: 24px;
`;

const CardBody = styled(Box)``;

const CardHeader = styled(Box)``;

const StyledContainer = styled(Box)`
  width: 288px;
  border-radius: 2px;
  svg {
    fill: white;
  }
`;

export default Card;
