/**
 * Do not edit directly
 * Generated on Sun, 20 Mar 2022 23:10:04 GMT
 */

export const primary10 = "#f5fdf9";
export const primary20 = "#b7f7e0";
export const primary30 = "#98f0ed";
export const primary50 = "#6bd5f1";
export const primary60 = "#05d8e8";
export const primary200 = "#017ac8";
export const primary600 = "#003782";
export const primary900 = "#000a20";
export const dustyGray10 = "#f3f3f3";
export const dustyGray30 = "#dbdbdb";
export const dustyGray40 = "#c8c8c8";
export const dustyGray60 = "#b4b4b4";
export const dustyGray80 = "#9a9a9a";
export const dustyGray200 = "#6c6c6c";
export const dustyGray500 = "#3a3a3a";
export const dustyGray700 = "#242424";
export const secondary20 = "#ffe5e6";
export const secondary50 = "#ffb1b9";
export const secondary70 = "#ff89a0";
export const secondary90 = "#ff618d";
export const secondary300 = "#ff296d";
export const secondary500 = "#b41641";
export const secondary800 = "#620617";
export const secondary900 = "#3c0006";
export const green20 = "#d5f6f9";
export const green30 = "#97eaee";
export const green50 = "#5ee0da";
export const green60 = "#35d5b5";
export const green70 = "#20cd7a";
export const green300 = "#117c3d";
export const green600 = "#074815";
export const green700 = "#033603";
export const neutralWhite = "#ffffff";
export const neutralBlack = "#000000";