import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { Web3Provider } from "@ethersproject/providers";

import { NetworkConnector } from "./NetworkConnector";
import { DEFAULT_CHAIN_ID } from "lib/constants";

const supportedChainIds = [1, 42];

const RPC = {
  1: "https://mainnet.infura.io/v3/a94f7c55fe10418ba556ac1cf0c00ea2",
  42: "https://kovan.infura.io/v3/a94f7c55fe10418ba556ac1cf0c00ea2",
};

export const network = new NetworkConnector({
  defaultChainId: DEFAULT_CHAIN_ID,
  urls: RPC,
});

export const injected = new InjectedConnector({ supportedChainIds });

const POLLING_INTERVAL = 12_000;
const walletconnect = new WalletConnectConnector({
  rpc: { 1: RPC[1] },
  bridge: "https://bridge.walletconnect.org",
  qrcode: true,
});

enum ConnectorNames {
  Injected = "injected",
  WalletConnect = "walletconnect",
}

export const connectorsByName: any = {
  [ConnectorNames.Injected]: injected,
  [ConnectorNames.WalletConnect]: walletconnect,
};

export function getLibrary(provider: any): Web3Provider {
  const library = new Web3Provider(
    provider,
    typeof provider.chainId === "number"
      ? provider.chainId
      : typeof provider.chainId === "string"
      ? parseInt(provider.chainId)
      : "any",
  );
  library.pollingInterval = 15_000;
  library.detectNetwork().then(network => {
    const networkPollingInterval = POLLING_INTERVAL;
    if (networkPollingInterval) {
      console.debug("Setting polling interval", networkPollingInterval);
      library.pollingInterval = networkPollingInterval;
    }
  });
  return library;
}
