import { FC, useEffect } from "react";
import { useWeb3React } from "@web3-react/core";

import { network } from "lib/config";
import { NetworkContextName, DEFAULT_CHAIN_ID } from "lib/constants";
import useEagerConnect from "hooks/useEagerConnect";
import useInactiveListener from "hooks/useInactiveListener";

export const Web3ReactManager: FC = ({ children }) => {
  const { active } = useWeb3React();
  const { active: networkActive, error: networkError, activate: activateNetwork } = useWeb3React(NetworkContextName);

  // try to eagerly connect to an injected provider, if it exists and has granted access already
  const triedEager = useEagerConnect();

  // after eagerly trying injected, if the network connect ever isn't active or in an error state, activate itd
  useEffect(() => {
    const activate = async () => {
      if (triedEager && !networkActive && !networkError && !active) {
        network.changeChainId(DEFAULT_CHAIN_ID);
        activateNetwork(network);
      }
    };
    activate();
  }, [triedEager, networkActive, networkError, activateNetwork, active]);

  // when there's no account connected, react to logins (broadly speaking) on the injected provider, if it exists
  useInactiveListener(!triedEager);

  return <>{children}</>;
};

export default Web3ReactManager;
