/**
 * Do not edit directly
 * Generated on Sun, 20 Mar 2022 23:10:04 GMT
 */

export const mediumDefaultRegular = {"fontFamily":"Minecraftia","fontWeight":400,"lineHeight":"20px","fontSize":"16px","letterSpacing":"0%","paragraphSpacing":"12px","textCase":"$textCase.none","textDecoration":"none"};
export const mediumDefaultBold = {"fontFamily":"Minecraftia","fontWeight":700,"lineHeight":"20px","fontSize":"16px","letterSpacing":"0%","paragraphSpacing":"12px","textCase":"$textCase.none","textDecoration":"none"};
export const mediumItalicRegular = {"fontFamily":"Minecraftia","fontWeight":400,"lineHeight":"20px","fontSize":"16px","letterSpacing":"0%","paragraphSpacing":"12px","textCase":"$textCase.none","textDecoration":"none"};
export const mediumItalicBold = {"fontFamily":"Minecraftia","fontWeight":700,"lineHeight":"20px","fontSize":"16px","letterSpacing":"0%","paragraphSpacing":"12px","textCase":"$textCase.none","textDecoration":"none"};
export const smallDefaultRegular = {"fontFamily":"Minecraftia","fontWeight":400,"lineHeight":"18px","fontSize":"14px","letterSpacing":"0%","paragraphSpacing":"12px","textCase":"$textCase.none","textDecoration":"none"};
export const smallDefaultBold = {"fontFamily":"Minecraftia","fontWeight":700,"lineHeight":"18px","fontSize":"14px","letterSpacing":"0%","paragraphSpacing":"12px","textCase":"$textCase.none","textDecoration":"none"};
export const smallItalicRegular = {"fontFamily":"Minecraftia","fontWeight":400,"lineHeight":"18px","fontSize":"14px","letterSpacing":"0%","paragraphSpacing":"12px","textCase":"$textCase.none","textDecoration":"none"};
export const smallItalicBold = {"fontFamily":"Minecraftia","fontWeight":700,"lineHeight":"18px","fontSize":"14px","letterSpacing":"0%","paragraphSpacing":"12px","textCase":"$textCase.none","textDecoration":"none"};
export const largeRegular = {"fontFamily":"Minecraftia","fontWeight":400,"lineHeight":"24px","fontSize":"20px","letterSpacing":"0%","paragraphSpacing":"12px","textCase":"$textCase.none","textDecoration":"none"};
export const largeBold = {"fontFamily":"Minecraftia","fontWeight":700,"lineHeight":"24px","fontSize":"20px","letterSpacing":"0%","paragraphSpacing":"12px","textCase":"$textCase.none","textDecoration":"none"};
export const largeItalicRegular = {"fontFamily":"Minecraftia","fontWeight":400,"lineHeight":"24px","fontSize":"20px","letterSpacing":"0%","paragraphSpacing":"12px","textCase":"$textCase.none","textDecoration":"none"};
export const largeItalicBold = {"fontFamily":"Minecraftia","fontWeight":700,"lineHeight":"24px","fontSize":"20px","letterSpacing":"0%","paragraphSpacing":"12px","textCase":"$textCase.none","textDecoration":"none"};