import { Box, useTheme } from "@mui/material";
import { calcTimeProgressPercentage } from "lib/utils/date";

const ProgressBar = ({ start, end }: any) => {
  const progressPercentage = calcTimeProgressPercentage(start, end);
  const theme = useTheme();
  return (
    <Box border={`1px solid ${theme.palette.primary20}`} p={0.5} width="100%">
      <Box width={`${progressPercentage}%`} bgcolor="primary10" height="10px" />
    </Box>
  );
};

export default ProgressBar;
