import React from "react";
import styled from "styled-components";
import { Box, BoxProps } from "@mui/material";

interface AvatarContainerProps extends BoxProps {
  scale?: number;
  background?: string;
}

const AvatarContainer: React.FC<AvatarContainerProps> = ({ children, scale = 8, background, ...props }) => {
  return (
    <>
      {children && (
        <StyledContainer background={background} scale={scale} {...props}>
          {children}
        </StyledContainer>
      )}
    </>
  );
};

const StyledContainer = styled(Box)<any>`
  display: flex;
  ${({ background }) => (background ? `background: ${background};` : "")}
  width: ${({ scale }) => {
    if (scale === 0) return "12";
    if (scale === 1) return "16";
    if (scale === 2) return "20";
    if (scale === 3) return "24";
    if (scale === 4) return "32";
    if (scale === 5) return "40";
    if (scale === 6) return "48";
    if (scale === 7) return "88";
    if (scale === 8) return "160";
  }}px;
  height: ${({ scale }) => {
    if (scale === 0) return "12";
    if (scale === 1) return "16";
    if (scale === 2) return "20";
    if (scale === 3) return "24";
    if (scale === 4) return "32";
    if (scale === 5) return "40";
    if (scale === 6) return "48";
    if (scale === 7) return "88";
    if (scale === 8) return "160";
  }}px;
  position: relative;
`;

export default AvatarContainer;
