export const NetworkContextName = "NETWORK";

export const connectorLocalStorageKey = "connectorId";

export const cardsIPFSToken = "QmerUPpF2h22W85oNWn5bnrmw6nmBfZr1mKHAomu2StFhX";

export enum ChainId {
  ETHEREUM = 1,
  KOVAN = 42,
}

export const explorers: { [chain: number]: string } = {
  [ChainId.ETHEREUM]: "https://etherscan.io",
  [ChainId.KOVAN]: "https://kovan.etherscan.io",
};

export const DEFAULT_CHAIN_ID = ChainId.ETHEREUM;
// export const DEFAULT_CHAIN_ID = ChainId.KOVAN;
