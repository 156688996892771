import React, { useState } from "react";
import styled from "styled-components";
import { Box } from "@mui/material";

type Props = {
  checked?: boolean;
  setChecked?: (checked: boolean) => void;
};
const Checkbox: React.FC<Props> = ({ checked, setChecked }) => {
  return (
    <StyledContainer onClick={() => setChecked && setChecked(!checked)}>
      {checked && (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.20033 2.79984V0.666504H11.3337V2.79984H9.20033L7.06699 4.93317V2.79984H9.20033V4.93317H7.06699L4.93366 7.0665V4.93317H7.06699V7.0665H4.93366H2.80033V2.79984H0.666992V11.3332H2.80033V9.19984H4.93366V7.0665L9.20033 2.79984Z"
            fill="#05D8E8"
          />
        </svg>
      )}
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)`
  position: relative;
  width: 20px;
  height: 20px;
  background: #0178c5;
  border: 2px solid #97f0ed;
  cursor: pointer;
  > svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

export default Checkbox;
