import React from "react";
import styled from "styled-components";
import { Box } from "@mui/material";

interface SvgProps {
  color?: string;
}

const SvgIcon: React.FC<SvgProps> = ({ color = "#FF296D" }) => {
  return (
    <StyledContainer
      component="svg"
      width="18"
      height="10"
      {...{ viewBox: "0 0 18 10" }}
      {...{ fill: color }}
      {...{ xmlns: "http://www.w3.org/2000/svg" }}
    >
      <path d="M0.666992 0.833374V2.50886H2.36561V4.15496H0.666992V5.79285L4.0463 5.83941V0.833374H0.666992Z" />
      <path d="M5.69671 7.52142H0.666992V9.16508H7.38476V2.50886H12.4031V4.15496H9.03518V9.16508H17.3337V0.835006H15.7358V7.52224H10.7167V5.84023H14.0519V0.83419H5.69671V7.52142Z" />
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)``;

export default SvgIcon;
