import React from "react";
import { useTheme, Box, Fade, Typography, Link } from "@mui/material";

import AvatarContainer from "components/ds/Containers/AvatarContainer";
import useSubscription from "hooks/useSubscription";
import useActiveWeb3React from "hooks/useActiveWeb3React";
import { DepositIcon, ToastInfoIcon, AlertIcon, CheckIcon, CloseIcon, ExternalLinkIcon } from "components/ds";
import UI, { ToastProps } from "lib/store/ui";
import { getExplorerUrl } from "lib/functions";

const Toast: React.FC = () => {
  const theme = useTheme();
  const { chainId } = useActiveWeb3React();
  const {
    variant = "success",
    isOpen,
    message = "",
    link,
    linkLabel,
    icon,
    txHash,
  }: ToastProps = useSubscription(UI.toastInfo);

  const icons: { [icon: string]: any } = {
    error: AlertIcon,
    success: CheckIcon,
    info: ToastInfoIcon,
    deposit: DepositIcon,
  };
  const Icon = icon ? icons[icon] : icons[variant];
  const bgcolor = { success: "primary60", error: "secondary500", info: "dustyGray500" }[variant];
  const textColor = {
    success: theme.palette.dustyGray700,
    error: theme.palette.dustyGray10,
    info: theme.palette.dustyGray10,
  }[variant];

  const url = link ? link : txHash ? getExplorerUrl(txHash, chainId) : undefined;
  const urlLabel = linkLabel ? linkLabel : url ? "View on Explorer" : undefined;
  return (
    <Fade in={isOpen} timeout={10}>
      <Box
        zIndex="9999"
        boxShadow={theme.shadows[0]}
        bgcolor={bgcolor}
        position="fixed"
        right={["5px", "16px"]}
        top={["60px", "80px"]}
        borderRadius="2px"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box pl={1} pt={1} pb={1} display="flex" alignItems="center">
          <Box padding="2px 6px 2px 4px">
            <AvatarContainer scale={3}>
              <Icon height={24} width={24} color={textColor} />
            </AvatarContainer>
          </Box>
          <Box mx={1}>
            <Typography
              variant="body2"
              color={textColor}
              whiteSpace="pre-line"
              dangerouslySetInnerHTML={{ __html: message }}
            />
            {url && urlLabel ? (
              <Link
                href={url}
                target="_blank"
                sx={{ textDecoration: "none", "&:hover": { textDecoration: "underline" } }}
                display="flex"
                alignItems="center"
              >
                <Typography variant="body2" color={textColor} mr={1}>
                  {urlLabel}
                </Typography>
                <Box width="16px" height="16px">
                  <ExternalLinkIcon color={textColor} />
                </Box>
              </Link>
            ) : null}
          </Box>
        </Box>
        <Box component="button" bgcolor="transparent" p={1} onClick={() => UI.hideToast()}>
          <CloseIcon width={14} height={14} color={textColor} />
        </Box>
      </Box>
    </Fade>
  );
};

export default Toast;
