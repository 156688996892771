import { parseUnits, formatUnits } from "@ethersproject/units";
import { BigNumber } from "ethers";

export function parseBalance(value: string, decimals = 18) {
  return parseUnits(value || "0", decimals);
}

export function formatBalance(value: any, unitName: string | number = "ether") {
  return formatUnits(value, unitName);
}

export const toBN = (num: number) => {
  return BigNumber.from(num);
};

export const bnToNum = (bigNum: BigNumber) => {
  return Number(bigNum.toString());
};

export function convertToInternationalCurrencySystem(value: number | string) {
  // 2 decimals
  const labelValue = Math.round(Number(value) * 100) / 100;

  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e9
    ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + "B"
    : // Six Zeroes for Millions
    Math.abs(Number(labelValue)) >= 1.0e6
    ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + "M"
    : Math.abs(Number(labelValue)) >= 1.0e3
    ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + "K"
    : Math.abs(Number(labelValue));
}

/** separates thousand by comma, truncates decimals */
export function formatNumber(value: any, opts = {}) {
  return new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    ...opts,
  }).format(value);
}
