import React from "react";
import { Box, BoxProps, Modal, useTheme } from "@mui/material";
import styled from "styled-components";

import { CloseIcon } from "components/ds/Icons";

interface ModalProps extends BoxProps {
  scale?: Number;
  open: boolean;
  onClose: any;
}

const Modals: React.FC<ModalProps> = ({ scale = 0, open, onClose, children, ...props }) => {
  const theme = useTheme();
  return (
    <Modal open={open} onClose={onClose}>
      <StyledContainer position="relative" scale={scale} {...props}>
        <CloseButton position="absolute" width="20px" height="20px" right={10} top={10} onClick={onClose}>
          <CloseIcon color={theme.palette.dustyGray700} width="20" height="20" />
        </CloseButton>
        {children}
      </StyledContainer>
    </Modal>
  );
};

const CloseButton = styled(Box)`
  background: transparent;
  cursor: pointer;
`;

export const StyledContainer = styled(Box)<any>`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #b5f7e0;
  width: ${({ scale }) => {
    if (scale === 0) return "304";
    if (scale === 1) return "384";
    if (scale === 2) return "448";
    if (scale === 3) return "512";
    if (scale === 4) return "640";
  }}px;
  max-height: calc(100% - 50px);
  overflow-y: auto;
  max-width: calc(100% - 32px);
  box-shadow: -4px 4px 0px 0.5px #ff296d;
`;

export default Modals;
